import { createStyles, makeStyles } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

const useStyles = (theme) => makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: theme.palette.background.default, // ✅ Dynamically applied
      color: theme.palette.text.primary, // ✅ Dynamically applied
      height: '100%',
      width: '100%',
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

const GlobalStyles = () => {
  const theme = useTheme(); // ✅ Get theme
  const useStylesWithTheme = useStyles(theme); // ✅ Pass theme dynamically
  useStylesWithTheme();

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
    document.body.style.color = theme.palette.text.primary;
  }, [theme]); // ✅ Ensure background updates on theme change

  return null;
};

export default GlobalStyles;
