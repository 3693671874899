/* eslint-disable */
import React from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import { Helmet } from 'react-helmet';
import ListView from './ListView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  return (
      <Page
        className={classes.root}
        title="Customer Management System"
    >
      <Helmet>
        <title>LMS</title>
        <meta property="og:title" content="LMS" />
        <meta property="og:description" content="Lead Management System" />
        <meta property="og:image" content="URL of an image to display" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
        <Container maxWidth={false}>
          <Box mt={3}>
            <Grid
              container
              spacing={1}
            >
                <Grid
                  item
                  key='mm-1'
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Toolbar />
                  <ListView />
                </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
  );
};

export default ProductList;
