import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, NavLink as RouterLink } from 'react-router-dom'; // ✅ For URL manipulation
import DataTable from 'react-data-table-component';
import {
  TextField, Paper, CircularProgress, Typography, IconButton, Button, makeStyles
} from '@material-ui/core';
import LeadlistApiCall from 'src/utils/LeadlistApiCall';
import moment from 'moment';
import { Folder, Delete } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import differenceBy from 'lodash/differenceBy';
/**
 * Code for Data table theme change dark & light
 */
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from 'src/components/DataTable/ThemeDatatable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const LeadlistTable = (param) => {
  const { refreshTable, setRefreshTable } = param;
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page'), 10) || 1;
  const initialSearch = queryParams.get('search') || '';
  const [leadlists, setLeadlists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(initialSearch);
  const [page, setPage] = useState(initialPage);
  const [selectedRows, setSelectedRows] = useState([]); // ✅ Selected Rows
  const [totalRows, setTotalRows] = useState(0);
  const limit = 10; // Items per page
  const [toggleCleared, setToggleCleared] = useState(false);

  // 🔄 **Update URL when page or search query changes**
  useEffect(() => {
    const params = new URLSearchParams();
    if (searchQuery) params.set('search', searchQuery);
    if (page > 1) params.set('page', page);
    navigate({ search: params.toString() }, { replace: true });
  }, [page, searchQuery, navigate]);

  // 🔄 **Fetch Leadlists when URL params change**
  useEffect(() => {
    // console.log('Fetching data with', { page, searchQuery, refreshTable });

    const fetchLeadlists = async () => {
      setLoading(true);
      const skip = (page - 1) * limit;
      const resp = await LeadlistApiCall.getAllLeadlists({ skip, limit, name: searchQuery });

      if (resp.status === 200) {
        setLeadlists(resp.leadlists);
        setTotalRows(resp.totalRecords);
      } else {
        console.error('Failed to fetch leadlists');
      }
      setLoading(false);
    };

    fetchLeadlists();
  }, [page, searchQuery, refreshTable]);

  /**
   * Deletes a lead list and all associated leads.
   * @param {string} leadListId - The ID of the lead list to delete.
   */
  const handleDelete = async (leadListId) => {
  // console.log('id', dsrId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            // console.log('yes');
            LeadlistApiCall.deleteLeadlist(leadListId);
            setRefreshTable((prev) => !prev);
          }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  };
  /**
   * Deletes a lead list and all associated leads.
   * @param {string} leadListId - The ID of the lead list to delete.
   */
  const handleBulkDelete = async () => {
    if (selectedRows.length === 0) return;
    // console.log('id', dsrId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            // console.log('yes');
            // const leadlistIds = selectedRows.map((row) => row._id);
            // const resp = await LeadlistApiCall.deleteBulkLeadLists(leadlistIds);
            const leadlistIds = selectedRows.map((row) => row._id);
            const resp = await LeadlistApiCall.deleteBulkLeadLists(leadlistIds);
            if (resp.status === 200) {
              // alert('Selected lead lists deleted successfully!');
              setLeadlists(differenceBy(leadlists, selectedRows, '_id')); // Remove deleted items
              setSelectedRows([]); // Clear selection
              setToggleCleared(!toggleCleared); // Reset table state
              setRefreshTable((prev) => !prev);
            } else {
              console.log('Failed to delete lead lists');
            }
          }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  };
  // Handle Row Selection
  const handleSelectedRowsChange = ({ selectedRows: newSelectedRows }) => {
    setSelectedRows(newSelectedRows);
    console.log(selectedRows, newSelectedRows);
  };
  // Context actions (Delete button)
  const contextActions = useMemo(() => (
    <Button
      key="delete"
      onClick={handleBulkDelete}
      style={{ backgroundColor: 'red', color: 'white', marginBottom: '10px' }}
      startIcon={<Delete />}
    >
      Delete Selected (
      {selectedRows.length}
      )
    </Button>
  ), [selectedRows, toggleCleared]);

  // Table Columns
  const columns = [
    {
      name: 'ID', selector: (row, index) => index + 1, sortable: true, width: '80px'
    },
    {
      name: 'List Name',
      selector: (row) => (
        <Typography
          color="textSecondary"
          display="inline"
          variant="body2"
          style={{
            display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#1976d2'
          }}
          component={RouterLink}
          to={`/app/lms/view/${row._id}`}
        >
          <Folder style={{ marginRight: '8px' }} />
          {row.name}
        </Typography>
      ),
      sortable: true
    },
    // {
    //   name: 'Name',
    //   selector: (row) => (
    //     <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    //       <Folder style={{ color: '#1976d2' }} />
    //       {/* Blue Folder Icon */}
    //       {row.name}
    //     </div>
    //   ),
    //   sortable: true
    // },
    { name: 'Description', selector: (row) => row.description || 'N/A', sortable: true },
    // {
    //   name: 'Created By',
    //   selector: (row) => `${row.createdBy.fname} ${row.createdBy.lname}`,
    //   sortable: true
    // },
    { name: 'Created At', selector: (row) => moment(row.createdAt).format('DD MMM YYYY, hh:mm:ss A'), sortable: true },
    {
      name: 'Actions',
      selector: (row) => (
        <IconButton style={{ color: 'red' }} aria-label="delete" onClick={() => handleDelete(row._id)}>
          <Delete />
        </IconButton>
      ),
      width: '100px',
      ignoreRowClick: true,
      allowOverflow: true
    },
  ];

  return (
    <Paper style={{ padding: '20px', marginTop: '20px' }} className={classes.root}>
      {/* Search Box */}
      <TextField
        label="Search Leadlists"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        <DataTable
          // title="Lead Lists"
          columns={columns}
          data={leadlists}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={limit}
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationDefaultPage={page} // ✅ Keeps the correct page number
          onChangePage={(newPage) => setPage(newPage)} // ✅ Updates state & URL
          highlightOnHover
          responsive
          customStyles={{}}
          theme={rdtTheme} // Dynamically apply theme
          selectableRows // ✅ Enable row selection
          onSelectedRowsChange={handleSelectedRowsChange} // ✅ Handle selection
          contextActions={contextActions}
        />
      )}
    </Paper>
  );
};

export default LeadlistTable;
