import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from './inLocalStorageJwt';

const createLeadlist = async (leadlistBody) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/leadlists/create`, leadlistBody, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, leadlist: response.data.leadlist, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, leadlist: '', message: error.response.data.message };
  }
};

const getLeadlistById = async (leadlistId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/leadlists/view/${leadlistId}`, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, leadlist: response.data.leadlist, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, leadlist: '', message: error.response.data.message };
  }
};

const deleteLeadlist = async (leadlistId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/leadlists/delete/${leadlistId}`, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, message: error.response.data.message };
  }
};

const deleteBulkLeadLists = async (leadlistIds) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/leadlists/deleteBulk`,
      { leadlistIds }, // Send selected lead lists in the request body
      options
    );

    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return { status: 200, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response?.data?.message || 'Failed to delete lead lists'}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return { status: 400, message: error.response?.data?.message || 'Failed to delete lead lists' };
  }
};

const getAllLeadlists = async (fields) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    },
  };
  const {
    skip, limit, name, desc
  } = fields;
  let searchQuery = `?skip=${skip}&limit=${limit}`;

  if (name) {
    searchQuery += `&name=${name}`;
    console.log(fields, searchQuery);
  }
  if (desc) {
    searchQuery += `&desc=${desc}`;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/leadlists/list/${searchQuery}`,
      options
    );

    return {
      status: 200,
      leadlists: response.data.result.leadlists,
      totalRecords: response.data.result.totalRecords,
      message: response.data.message,
    };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return {
      status: 400,
      leadlists: '',
      message: error.response.data.message,
    };
  }
};

export default {
  createLeadlist,
  getLeadlistById,
  deleteLeadlist,
  getAllLeadlists,
  deleteBulkLeadLists,
};
