import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

const Page = forwardRef(({
  children,
  title = '',
  ...rest
}, ref) => {
  const theme = useTheme(); // Get current theme
  return (
    <div
      ref={ref}
      {...rest}
      style={{
        background: theme.palette.mode === 'dark'
          ? 'linear-gradient(to bottom, #121212, #1e1e1e)' // Dark mode gradient
          : 'linear-gradient(to bottom, #f5f7fa, #dfe9f3)', // Light mode gradient
        color: theme.palette.text.primary,
        minHeight: '100vh',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // padding: '20px'
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
