import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';

// const theme = createTheme({
//   palette: {
//     background: {
//       dark: '#F4F6F8',
//       default: colors.common.white,
//       paper: colors.common.white
//     },
//     primary: {
//       main: colors.indigo[500]
//     },
//     secondary: {
//       main: colors.indigo[500]
//     },
//     text: {
//       primary: colors.blueGrey[900],
//       secondary: colors.blueGrey[600]
//     }
//   },
//   shadows,
//   typography
// });

// export default theme;

const lightTheme = createTheme({
  palette: {
    mode: 'light', // Light Mode
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: colors.indigo[500]
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Dark Mode
    background: {
      default: colors.grey[900],
      paper: colors.grey[800]
    },
    primary: {
      main: colors.blue[300]
    },
    secondary: {
      main: colors.blue[300]
    },
    text: {
      primary: colors.common.white,
      secondary: colors.grey[400]
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'yellow', // ✅ Default link color in dark mode
          '&:hover': {
            color: 'orange', // ✅ Change color on hover
          },
        },
      },
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: '#1E1E1E', // Dark background for AppBar
        color: '#ffffff',
      },
    },
  },
  shadows,
  typography
});

export { lightTheme, darkTheme };
