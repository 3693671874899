/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Container,
  makeStyles,
  Link,
  Typography,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Page from 'src/components/Page';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import moment from 'moment';
import ViewIcon from '@material-ui/icons/Visibility';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import Create from './Create';
import GroupContext from 'src/contexts/GroupContext';
import { useNavigate } from 'react-router-dom';
/**
 * Code for Data table theme change dark & light
 */
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from 'src/components/DataTable/ThemeDatatable';

// import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: theme.spacing(3)
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  },
  statusTaskLbl: {
    padding: '3px',
    borderRadius: '5px',
    color: 'white'
  },
  headerSt: {
    fontSize: '15px'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  }
}));

const List = (data, ...rest) => {
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const groupContext = useContext(GroupContext);
  const navigate = useNavigate();

  data=data.data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  async function deleteGroup(groupId,page) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
   await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/groups/delete/${groupId}`, options)
      .then((resp) => {
        console.log('del');
        // navigate('/app/customers');
      })
      .catch((error) => {
        console.log(error);
      });
      groupContext.fetchGroups(currentPage, perPage);
    
  }
  function handleViewGroup(groupId) {
    navigate(`/app/group/${groupId}`);
  }
  function handleDelete(groupId) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); deleteGroup(groupId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }

  const columns = [
    {
      name: <span className={classes.headerSt}>Group Name</span>,
      selector: 'gname',
      sortable: true,
      //right: true,
      cell: (row) => <div style={{width:'100%'}}>{row.gname}</div>
    },
    // {
    //   name: 'Type',
    //   selector: 'typeName',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Content',
    //   selector: 'type',
    //   sortable: true,
    //   center: true,
    //   cell: (row) => (<div>{ row.type? row.type.name: 'N/A' } </div>)
    // },
    {
      name: <span className={classes.headerSt}>Description</span>,
      selector: 'gdesc',
      sortable: true,
      center: true,
      cell: (row) => (<div>{ row.gdesc } </div>)
    },
    // {
    //     name: 'Task Status',
    //     selector: 'status',
    //     sortable: true,
    //     center: true,
    //     cell: (row) => (<div>{ row.status } </div>)
    //   },
    {
      name: <span className={classes.headerSt}>Action</span>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        <>
            <IconButton color="primary" size="small" variant="contained" onClick={() => { handleViewGroup(row.id); }}>
              <ViewIcon />
            </IconButton>
            <Create groupId={row.id} fetchGroups={groupContext.fetchGroups} />
          <div>
            <IconButton aria-label="delete" color="secondary" onClick={() => { handleDelete(row.id); }}>
              <DeleteIcon style={{color:'#ff6666'}} />
            </IconButton>
          </div>
        </>
      )
    }
  ];
  // const fetchGroups = async (page) => {
  //   const offset = (page - 1) * perPage;
  //   setLoading(true);
  //   const postData = {
  //     createdBy: getLoggedinUserData.id,
  //     skip: offset,
  //     limit: perPage
  //   };
  //   // const response = await axios.get(
  //   //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
  //   // );
  //   var filters = `?createdBy=${getLoggedinUserData.id}&skip=${offset}&limit=${perPage}`;
  //   await axios.get(`${process.env.REACT_APP_API_BASE_URL}/groups${filters}`, options)
  //     .then(async (res) => {
  //       // setReportList(res.data.dsreports);
  //       // console.log(res.data.result);
  //       setData(res.data.data.groups);
  //       setTotalRows(res.data.data.totalGroups);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handlePageChange = (page) => {
    groupContext.fetchGroups(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    await groupContext.fetchGroups(page, perPage);
    // setData(response.data.data);
    setLoading(false);
  };

  // const ExpandedRow = (row) => {
  //   const applicationOb = row.data;
  //   const bull = <span className={classes.bullet}>•</span>;
  //   return (<div className={classes.root}>
  //          <Grid container spacing={3}>
  //             <Card className={classes.formExpndCard}>
  //               <Table>
  //                 <Thead>
  //                   <Tr>
  //                     <Th className={classes.respHeader}>Name</Th>
  //                     <Th className={classes.respHeader}>Desc</Th>
  //                   </Tr>
  //                 </Thead>
  //                 <Tbody>
  //                   <Tr>
  //                     <Td className={classes.respHeader}>{applicationOb.gname? applicationOb.gname: 'N/A'}</Td>
  //                     <Td className={classes.respHeader}>{applicationOb.gdesc? applicationOb.gdesc: 'N/A'}</Td>
  //                   </Tr>
  //                 </Tbody>
  //               </Table>
  //             </Card>
  //          </Grid>
  //      </div>);
  // };
  const tableData = {
    columns,
    data
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     // You can await here
  //     await groupContext.fetchGroups(1, perPage);
  //   }
  //   fetchData();
  //   console.log( data );
  // }, []); // Or [] if effect doesn't need props or state
  return (
        <Container className={classes.mainContainer} maxWidth={false}>
          {/* <DsrList dsrList={dsrList} /> */}
          <DataTableExtensions {...tableData}>
              <DataTable
                title="Groups List(Created By me)"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                filter
                customStyles={{}}
                theme={rdtTheme} // Dynamically apply theme
                paginationServer
                paginationTotalRows={totalRows}
                // selectableRows
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
                // expandableRows
                // expandableRowDisabled={row => row.disabled}
                // expandableRowsComponent={<ExpandedRow />}
              />
            </DataTableExtensions>
        </Container>
  );
};

List.propTypes = {
  className: PropTypes.string,
  // fetchGroups: PropTypes.object
};
export default List;
