/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import moment from 'moment';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';

import { useParams, useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import DataTable from 'src/components/DataTable/DataTable';
import DailyReport from 'src/components/DataTable/DailyReport';
import Courses from 'src/components/DataTable/Courses';
import CustomerList from 'src/components/DataTable/CustomersList';
// import Calling from 'src/components/Calling/Calling';
import SubscriptionOrder from 'src/components/DataTable/SubscriptionOrder';
import Tree from 'react-d3-tree';
import { browserHistory } from 'react-router';
import EditIcon from "@mui/icons-material/Edit";

// import placeholder from '../src/static/images/avatars/avatar_6.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './custom-tree.css';
import helpers from "./helpers";

import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CardActions,
  CircularProgress,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Grid,
  IconButton
} from '@material-ui/core';
import UserContext from 'src/contexts/UserContext';

const placeholder = '/static/images/avatars/user-logo-avtar.png';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  tableHd: {
      marginLeft: '10px'
  }
}));
const containerStyles = {
    width: "100vw",
    height: "100vh"
  };
// const renderForeignObjectNode = ({
//     nodeDatum,
//     toggleNode,
//     foreignObjectProps
//   }) => (
//     <g>
//       <circle r={15}></circle>
//       {/* `foreignObject` requires width & height to be explicitly set. */}
//       <foreignObject {...foreignObjectProps}>
//         <div style={{ border: "1px solid black", backgroundColor: "#dedede" }}>
//           <h3 style={{ textAlign: "center" }}>{nodeDatum.name}</h3>
//           {nodeDatum.children && (
//             <button style={{ width: "100%" }} onClick={toggleNode}>
//               {nodeDatum.__rd3t.collapsed ? "Expand" : "Collapse"}
//             </button>
//           )}
//         </div>
//       </foreignObject>
//     </g>
//   );

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
//   const userContext = useContext(UserContext);
  const location = useLocation();

  let { uid } = useParams();
  const [userId, SetuserId] = useState(uid);
//   let pathname = window.location.pathname;
  const [pathname, SetPathname] = useState(window.location.pathname);
  const [customerData, setCustomerData] = useState();
  const [isMyPartner, setIsMyPartner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [editedUser, setEditedUser] = useState({ name: "", email: "", phone: "" });
  const [ismyProfilePage, setisMyProfilePage] = useState();
  const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
//   const [isDpSet, SetIsDpSet] = useState(!!loggidinUser.profile_pic);
  const [expandedDsr, setExpandedDsr] = React.useState(false);
  const [expandedCourses, setExpandedCourses] = React.useState(false);
  const [expandedActi, setExpandedActi] = React.useState(false);
  const [expandedChart, setExpandedChart] = React.useState(false);
  const [expandedCust, setExpandedCust] = React.useState(false);
  const [expandedOrders, setExpandedOrders] = React.useState(false);

  const [teamChart, SetTeamChart] = useState({});
  const [translate, containerRef] = helpers.useCenteredTree();
  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20 };
  const handleExpandClickDsr = () => {
    setExpandedDsr(!expandedDsr);
  };
  const handleExpandClickCourses = () => {
    setExpandedCourses(!expandedCourses);
  };
  const handleExpandClickActi = () => {
    setExpandedActi(!expandedActi);
  };
  const handleExpandClickChart = () => {
    setExpandedChart(!expandedChart);
  };
  const handleExpandClickCust = () => {
    setExpandedCust(!expandedCust);
  };
  const handleExpandedOrders = () => {
    setExpandedOrders(!expandedOrders);
  };
//   (function(history){
//     const pushState = history.pushState;
//     console.log(history.state);
//     history.pushState = function(state) {
//         if (typeof history.onpushstate == "function") {
//             history.onpushstate({state: state});
//         }
//         // Call your custom function here
//         // let uid = useParams();
//         return pushState.apply(history, arguments);
//     }
// })(window.history);
//   useEffect(() => {
//     if ('/app/my-profile/view/' === pathname) {
//         SetuserId(loggidinUser.id);
//         setisMyProfilePage(true);
//     }
//   }, [pathname]);

  React.useEffect(() => {
    // console.log('Location changed', uid);
    if ('/app/my-profile/view/' === pathname) {
        SetuserId(loggidinUser.id);
        setisMyProfilePage(true);
    }
    if(uid) {
        SetuserId(uid);
        setisMyProfilePage(false);
    }
  }, [location, pathname]);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      // console.log(userId);
      if(!userId){
        return;
      }
      const response = await ApiCall.getUserData(userId);
      setCustomerData(response);
      setLoading(false);
      // ...
    }
    fetchData();
  }, [userId]); // Or [] if effect doesn't need props or state

  useEffect(() => {
    async function fetchData() {
      // You can await here
      // console.log(loggidinUser, ismyProfilePage);
      if (customerData && customerData.email) {
        if(loggidinUser.email === customerData.bms_sponsor){
          // console.log("not sponsored by you");
          setIsMyPartner(true);
        }
        const response = await ApiCall.fetchUsersForChart(customerData.email);
        let tempChildren = [];
        if(response && response.length > 0) {
          await response.map((child) => {
            tempChildren.push({
                'name':child.fname,
                'id':child.id,
                'email': child.email
            });
        });
        }
        SetTeamChart({
            ...teamChart,
            name: customerData && customerData.fname,
            email: customerData && customerData.email,
            children: tempChildren
        });
      }
    //   setCustomerData(response);
      // ...
    }
    if(loggidinUser.role !== 'user'){
      fetchData();
    }
  }, [customerData]); // Or [] if effect doesn't need props or state
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const handleEditClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`/api/users/${userId}`, editedUser);
      setUser(editedUser);
      setOpen(false);
    } catch (err) {
      setError("Failed to update user data");
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  const handleonNodeClick = async (node) => {
    if(!!node.children){
      console.log("yes child");
    } else {
        node.children = [];
        const response = await ApiCall.fetchUsersForChart(node.email);
        if (!response || response.length === 0)
        {
            return;
        }
        let tempChildren = [];
        await response.map((child) => {
            tempChildren.push({
                'name':child.fname,
                'id':child.id,
                'email': child.email,
            });
        });
        const nodeF = await helpers.lookup(teamChart, node.email, tempChildren);
        SetTeamChart({...teamChart,...nodeF});

    }
  }
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            id="profile_pic"
            src= {customerData && customerData.profile_pic
                ? customerData.profile_pic : placeholder}
            alt='User'
          />
          <Typography variant="h5">User Profile</Typography>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
            style={{textAlign:'center'}}
          >
            {customerData && customerData.fname} <br />
            {/* {customerData && customerData.email !== loggidinUser.email 
            && <RouterLink to={"/meeting/room?callerId="+loggidinUser.email+'&receiverId='+customerData.email} component={RouterLink}><Button color="primary" variant="contained"> Enter Meeting Room  </Button></RouterLink> } */}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
           City: {`${customerData && customerData.address && customerData.address.length > 0
                && customerData.address[0].city ? customerData.address[0].city : 'N/A' }`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            State: {`${customerData && customerData.address && customerData.address.length > 0
                && customerData.address[0].state ? customerData.address[0].state : 'N/A' }`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            Phone: {customerData && customerData.phone
                ? <a href={`tel:+91-${customerData.phone}`}>{customerData.phone}</a> : 'N/A'}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            Email: {customerData && customerData.email
                ? customerData.email : 'N/A'}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            Sponsor: {customerData && customerData.bms_sponsor
                ? customerData.bms_sponsor : 'N/A'}
          </Typography>
        </Box>
        <Box
         alignItems="center"
         display="flex"
         flexDirection="column"
        >
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit User Details</DialogTitle>
            <DialogContent>
              <TextField label="Name" name="name" fullWidth margin="dense" value={editedUser.name} onChange={handleChange} />
              <TextField label="Email" name="email" fullWidth margin="dense" value={editedUser.email} onChange={handleChange} />
              <TextField label="Phone" name="phone" fullWidth margin="dense" value={editedUser.phone} onChange={handleChange} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">Cancel</Button>
              <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </CardContent>
      <Divider />
      {
        (ismyProfilePage || isMyPartner) &&
        (<><CardActions
          style={{backgroundColor: expandedActi? '#f2f2f2':'white'}}
          disableSpacing
          onClick={handleExpandClickActi}
        >
          {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton> */}
          <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
              className={classes.tableHd}
          >
              {/* {ismyProfilePage === true ? 'Your ':(customerData && customerData.fname)+'\'s '} */}
              Activity
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expandedActi,
            })}
            onClick={handleExpandClickActi}
            aria-expanded={expandedActi}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expandedActi} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <Typography paragraph>Method:</Typography> */}
            {userId && <DataTable userId={userId}/>}
          </CardContent>
        </Collapse>
        <Divider /></>
      )}
      {loggidinUser && loggidinUser.role !== 'user' && (ismyProfilePage || isMyPartner)
      && (
        <>
          <CardActions
            style={{backgroundColor: expandedDsr? '#f2f2f2':'white'}}
            disableSpacing
            onClick={handleExpandClickDsr}
          >
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.tableHd}
            >
                {/* {ismyProfilePage === true ? 'Your ':(customerData && customerData.fname)+'\'s '} */}
                Daily Status Reports
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedDsr,
              })}
              onClick={handleExpandClickDsr}
              aria-expanded={expandedDsr}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedDsr} timeout="auto" unmountOnExit>
            <CardContent>
              {/* <Typography paragraph>Method:</Typography> */}
              {userId && <DailyReport userId={userId}/>}
            </CardContent>
          </Collapse>
          <Divider />
        </>
      )}
      {loggidinUser && loggidinUser.role !== 'user' && (ismyProfilePage || isMyPartner)
      && (
        <>
          <CardActions
            style={{backgroundColor: expandedCourses? '#f2f2f2':'white'}}
            disableSpacing
            onClick={handleExpandClickCourses}
          >
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.tableHd}
            >
                {/* {ismyProfilePage === true ? 'Your ':(customerData && customerData.fname)+'\'s '} */}
                Courses
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedCourses,
              })}
              onClick={handleExpandClickCourses}
              aria-expanded={expandedCourses}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedCourses} timeout="auto" unmountOnExit>
            <CardContent>
              {/* <Typography paragraph>Method:</Typography> */}
              {userId && <Courses userId={userId}/>}
            </CardContent>
          </Collapse>
          <Divider />
        </>
      )}
      {loggidinUser && loggidinUser.role !== 'user' && (ismyProfilePage || isMyPartner)
      && (
        <>
          <CardActions
            style={{backgroundColor: expandedChart? '#f2f2f2':'white'}}
            disableSpacing
            onClick={handleExpandClickChart}
          >
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.tableHd}
            >
                {/* {ismyProfilePage === true ? 'Your ':(customerData && customerData.fname)+'\'s '} */}
                Partners Graph
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedChart,
              })}
              onClick={handleExpandClickChart}
              aria-expanded={expandedChart}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedChart} timeout="auto" unmountOnExit>
            <CardContent>
              {/* <Typography paragraph>Method:</Typography> */}
              {/* <DailyReport userId={userId}/> */}
              <div
                id="treeWrapper"
                style={{ width: '100%', height: '640px', border: '1px solid black' }} ref={containerRef}>
                <Tree
                    data={teamChart}
                    orientation="horizontal"
                    translate={translate}
                    onNodeClick={(node) => {handleonNodeClick(node);}}
                    // rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    // renderCustomNodeElement={(rd3tProps) =>
                    //     renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                    //     }
                />
              </div>
              {/* <div style={containerStyles} ref={containerRef}>
                <Tree
                    data={orgChart}
                    translate={translate}
                    nodeSize={nodeSize}
                    renderCustomNodeElement={(rd3tProps) =>
                    renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                    }
                    orientation="vertical"
                />
              </div> */}
            </CardContent>
          </Collapse>
          <Divider />
        </>
      )}
      {loggidinUser && loggidinUser.role !== 'user' && (ismyProfilePage || isMyPartner)
      && (
        <>
          <CardActions
            style={{backgroundColor: expandedCust? '#f2f2f2':'white'}}
            disableSpacing
            onClick={handleExpandClickCust}
          >
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.tableHd}
            >
                {/* {ismyProfilePage === true ? 'Your ':(customerData && customerData.fname)+'\'s '} */}
                Partners List
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedCust,
              })}
              onClick={handleExpandClickCust}
              aria-expanded={expandedCust}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedCust} timeout="auto" unmountOnExit>
            <CardContent>
              {/* <Typography paragraph>Method:</Typography> */}
              {/* <DailyReport userId={userId}/> */}
              {customerData && customerData.id === userId && <CustomerList sponsor={customerData.email}/>}
            </CardContent>
          </Collapse>
          <Divider />
        </>
      )}
      {(ismyProfilePage || (loggidinUser && customerData && loggidinUser.referral_code === customerData.referred_by))
      && (
        <>
          <CardActions
            style={{backgroundColor: expandedOrders? '#f2f2f2':'white'}}
            disableSpacing
            onClick={handleExpandedOrders}
          >
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.tableHd}
            >
                {/* {ismyProfilePage === true ? 'Your ':(customerData && customerData.fname)+'\'s '} */}
                Subscription Order History
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedOrders,
              })}
              onClick={handleExpandedOrders}
              aria-expanded={expandedOrders}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedOrders} timeout="auto" unmountOnExit>
            <CardContent>
              {/* <Typography paragraph>Method:</Typography> */}
              {/* <DailyReport userId={userId}/> */}
              {(ismyProfilePage || (loggidinUser.referral_code === customerData.referred_by)) && <SubscriptionOrder userId={userId}/>}
            </CardContent>
          </Collapse>
          <Divider />
        </>
      )}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
