/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import moment from 'moment';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import { toast } from 'react-toastify';
import moment from 'moment';
import DataTable from 'src/components/DataTable/DataTable';
// import placeholder from '../src/static/images/avatars/avatar_6.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import { format } from 'date-fns';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Slider from '@material-ui/core/Slider';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Grid,
  IconButton
} from '@material-ui/core';
import UserContext from 'src/contexts/UserContext';

const placeholder = '/static/images/avatars/user-logo-avtar.png';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));
function hmsToSecondsOnly(str) {
    var p = str.split(':'),
        s = 0, m = 1;

    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10);
        m *= 60;
    }

    return s;
}
const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  let { taskId } = useParams();
  const [taskActivity, setTaskActivity] = useState([]);
  const [taskDetails, setTaskDetails] = useState();
  const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
  const [expanded, setExpanded] = React.useState(true);
  const seekSlug = ['video_backward_at', 'video_forward_at'];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const task = await ApiCall.getTaskDetails(taskId);
      setTaskDetails(task);
      const response = await ApiCall.getTaskActivity(task.user.id, 'Task', taskId);
      setTaskActivity(response);
      // ...
    }
    fetchData();
  }, [taskId]); // Or [] if effect doesn't need props or state

  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  // const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
  const AuthToken = AuthTokenObject.authToken;
  function valuetext(value) {
    return `${value}°C`;
  }
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
}
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{
        minHeight: '100vh',  // Full viewport height
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {taskDetails && taskDetails.title}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
           Type: {taskDetails && taskDetails.typeName}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
           Total Played: {taskDetails && secondsToHms(taskDetails.totalPlayed)}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
        >
            Activity
          <Typography paragraph>Statistics: {taskActivity.length === 0? 'No ACtivity': ''}</Typography>
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{minHeight:'600px'}}>
          {/* <DataTable userId={userId}/> */}
          {taskActivity.map((activity) => (
                <Grid
                  item
                  style={{margin:'20px'}}
                  key={activity.id}
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <span>
                    <small>
                      <b>
                          {activity.activity==='video_play_at'? <span style={{color:'green'}}>{activity.object}</span>
                          : (activity.activity==='video_pause_at'?<span style={{color:'red'}}>{activity.object}</span>
                          :(activity.activity==='video_ended_at'?'Ended at:':(
                            activity.activity==='video_forward_at'? <span style={{color:'purple'}}>{activity.object}</span>
                            : (
                               activity.activity==='video_backward_at'? <span style={{color:'purple'}}>{activity.object}</span> : 'Unknown activity'
                            )
                          )))}
                      </b>
                            {' '}
                        { format(new Date(activity.log_at), 'MMM d, hh:mm a')} | <b>Length:</b> 
                        {seekSlug.indexOf(activity.activity) > -1? activity.play_pause_time.split('/')[2] : activity.play_pause_time.split('/')[1] }
                    </small>
                  </span>
                  {  seekSlug.indexOf(activity.activity) < 0
                      && (
                          <Slider
                            track={false}
                            valueLabelDisplay="off"
                            // disabled
                            value={[
                              hmsToSecondsOnly(activity.play_pause_time.split('/')[0]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[1]),
                              hmsToSecondsOnly(activity.play_pause_time.split('/')[0]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[1])
                            ]}
                            aria-labelledby="track-false-range-slider"
                            getAriaValueText={valuetext}
                            defaultValue={(hmsToSecondsOnly(activity.play_pause_time.split('/')[0]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[1]))}
                            marks={[
                                {
                                    value: 100,
                                    label: activity.play_pause_time.split('/')[1]
                                },
                                {
                                    value: (hmsToSecondsOnly(activity.play_pause_time.split('/')[0]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[1])),
                                    label: activity.play_pause_time.split('/')[0]
                                }
                            ]}
                         />
                        )
                  }
                  {  seekSlug.indexOf(activity.activity) > -1
                      && (
                          <Slider
                            // track={false}
                            value={[
                                hmsToSecondsOnly(activity.play_pause_time.split('/')[0]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[2]),
                                hmsToSecondsOnly(activity.play_pause_time.split('/')[1]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[2])
                            ]}
                            defaultValue={[
                                hmsToSecondsOnly(activity.play_pause_time.split('/')[0]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[2]),
                                hmsToSecondsOnly(activity.play_pause_time.split('/')[1]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[2])
                            ]}
                            // disabled
                            getAriaValueText={valuetext}
                            aria-labelledby="range-slider"
                            marks={[
                                {
                                    value: (hmsToSecondsOnly(activity.play_pause_time.split('/')[0]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[2])),
                                    label: activity.play_pause_time.split('/')[0]
                                },
                                {
                                    value: (hmsToSecondsOnly(activity.play_pause_time.split('/')[1]) * 100/hmsToSecondsOnly(activity.play_pause_time.split('/')[2])),
                                    label: activity.play_pause_time.split('/')[1]
                                }
                            ]}
                         />
                        )
                  }
                </Grid>
              ))}
        </CardContent>
      </Collapse>
      <Divider />
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
