/* eslint-disable */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Card,
  Container,
  makeStyles,
} from '@material-ui/core';
// import DsrList from './DsrList';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
// import ViewIcon from '@material-ui/icons/Visibility';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import Tooltip from '@material-ui/core/Tooltip';
import CouponApiCall from 'src/utils/CouponApiCall';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Toolbar from './Toolbar';
/*
 * Code for Data table theme change dark & light
 **/
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from 'src/components/DataTable/ThemeDatatable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  },
  inactiveIcon: {
    color: 'red'
  },
  activeIcon: {
    color: 'green'
  },
  subTitle : {
    fontSize: '10px'
  }
}));
function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const CustomerListView = (params, className, ...rest) => {
  const { userId } = params;
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  function handleDelete(dsrId) {
    // console.log('id', dsrId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  const toggleActiveCoupon = async(couponId) => {
    try {
      const resp = await CouponApiCall.toggleActiveCoupon(couponId);
      console.log(resp);
      if (resp.status === 200 && resp.coupon) {
        fetchDsrs(1);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    }
  }
  function handleActive(couponId) {
    confirmAlert({
      title: 'Confirm to change the Active/Inactive status',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); toggleActiveCoupon(couponId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>Status</b>,
      selector: 'is_active',
      sortable: true,
      center: true,
      cell: (row) => row.is_active ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Disabled</span>
    },
    {
      name: <b style={{ fontSize: '15px' }}>Code</b>,
      selector: 'code',
      sortable: true,
      center: true,
      cell: (row) =>row.code
    },
    {
      name: <b style={{ fontSize: '15px' }}>Discount</b>,
      selector: 'discount',
      sortable: true,
      center: true,
      cell: (row) => row.discount + ' %'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Expiry Date</b>,
      selector: 'expiryDate',
      sortable: true,
      center: true,
      cell: (row) => moment(row.expiryDate).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Usage Count</b>,
      selector: 'usageCount',
      sortable: true,
      center: true,
      cell: (row) => row.usageCount? row.usageCount : '-'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Usage Limit</b>,
      selector: 'usageLimit',
      sortable: true,
      center: true,
      cell: (row) => row.usageLimit? row.usageLimit : '-'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Action</b>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (

            <Tooltip title={row.is_active? 'Disable Code' : 'Enable Code'}>
              <IconButton color="primary" onClick={() => { handleActive(row._id); }}>
                {row.is_active ? <><ToggleOffIcon className={classes.inactiveIcon} /><span className={classes.subTitle}>Disable</span></> : <><ToggleOnIcon className={classes.activeIcon}/><span className={classes.subTitle}>Enable</span></> }
              </IconButton>
            </Tooltip>
      )
    }
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

  const fetchDsrs = async (page) => {
    // if (!userId) {
    //     return false;
    // }
    const offset = (page - 1) * perPage;
    setLoading(true);
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/coupons/list`, options)
      .then(async (res) => {
        if(res.data.coupons){
          setData(res.data.coupons);
          console.log(res.data.coupons);
          setTotalRows(res.data.coupons.length);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchDsrs(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    // setData(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchDsrs(1);
  }, [perPage]);
  useEffect(() => {
    fetchDsrs(1);
    // ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'User Reports', 'Check in to User Daily report List page.');
  }, []);
  const ExpandedRow = (row) => {
    // console.log(row);
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>description</Th>
                      {/* <Th className={classes.respHeader}>Third parties details</Th>
                      <Th className={classes.respHeader}>Retailing</Th>
                      <Th className={classes.respHeader}>Recruitment</Th>
                      <Th className={classes.respHeader}>Notes</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{applicationOb.course?applicationOb.course.description:null}</Td>
                      {/* <Td className={classes.respHeader}>{applicationOb.tp_details}</Td>
                      <Td className={classes.respHeader}>{applicationOb.retailing}</Td>
                      <Td className={classes.respHeader}>{applicationOb.recruitment}</Td>
                      <Td className={classes.respHeader}>{applicationOb.notes}</Td> */}
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
    <Container maxWidth={false}>
      <Box mt={3}>
        <Toolbar />
      </Box>
      <Box mt={3}>
        {/* <DsrList dsrList={dsrList} /> */}
        <DataTableExtensions {...tableData}>
          <DataTable
            title="Coupons List"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            filter
            paginationServer
            paginationTotalRows={totalRows}
            customStyles={{}}
            theme={rdtTheme} // Dynamically apply theme
            // selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover
            // expandableRows
            expandableRowDisabled={row => row.disabled}
            expandableRowsComponent={<ExpandedRow />}
          />
        </DataTableExtensions>
      </Box>
    </Container>
  );
};

export default CustomerListView;