/* eslint-disable */
import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from "@material-ui/core";
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // MUI 4 Icon
import Importer from "react-importer";
import LeadApiCall from 'src/utils/LeadApiCall';

const LeadImport = ({ leadlistId, setRefreshTable }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  /** Function to format Date of Birth */
  const formatDOB = (dob) => {
    if (!dob) return null;
    
    let parsedDate = new Date(dob);
    if (isNaN(parsedDate.getTime())) {
      const parts = dob.split(/[-/]/);
      if (parts.length === 3) {
        const [day, month, year] = parts.map(Number);
        parsedDate = new Date(year, month - 1, day);
      }
    }

    return isNaN(parsedDate.getTime()) ? null : parsedDate.toISOString().split("T")[0]; // Format YYYY-MM-DD
  };
  return (
    <div>
      {/* Small Import Button with Icon */}
      <Button 
        variant="contained" 
        color="primary" 
        startIcon={<CloudUploadIcon />}
        size="small" 
        onClick={handleOpen}
      >
        Import
      </Button>

      {/* Importer Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Import Leads</DialogTitle>
        <DialogContent>
          <Importer
            fields={[
              {
                label: "Lead Source",
                key: "leadSource",
                // validators: [{ validate: "required" }],
              },
              {
                label: "Name",
                key: "name",
                validators: [{ validate: "required" }],
              },
              {
                label: "Who Are You?",
                key: "jobRole",
                // validators: [{ validate: "required" }],
              },
              {
                label: "Email",
                key: "email",
                validators: [
                  // { validate: "required" },
                  { validate: "unique", error: "This email is not unique" },
                ],
              },
              {
                label: "State",
                key: "state",
                transformers: [{ transformer: "state_code" }],
              },
              {
                label: "City",
                key: "city",
                transformers: [{ transformer: "strip" }], // Ensures first letter is capitalized
              },
              {
                label: "Phone Number",
                key: "phone",
                validators: [
                  { validate: "required" },
                  { validate: "unique", error: "This phone number already exists" },
                ],
              },
              {
                label: "Date of Birth",
                key: "dob",
              },
              {
                label: "Gender",
                key: "gender",
                transformers: [{ transformer: "strip" }],
              },
            ]}
            onComplete={async (data) => {
              console.log("Imported Data:", data);
              const processedData = data.map((entry) => {
                const originalPhone = entry.phone || "";
                const cleanedPhone = originalPhone.replace(/\D/g, "").slice(-10); // Remove non-digits and extract last 10
                // console.log(`Original: ${originalPhone}, Cleaned: ${cleanedPhone}`);
                return {
                  ...entry,
                  phone: cleanedPhone,
                  dob: formatDOB(entry.dob),
                };
              });
              // console.log("Processed Data:", processedData);
             const response = await LeadApiCall.createBulkLead(leadlistId, processedData);
              // console.log(response);
             setRefreshTable((prev) => !prev);
              // return response;
              // handleClose(); // Close importer after import
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeadImport;
