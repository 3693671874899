/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography, Paper, Box } from '@material-ui/core';
// import DataTable from 'react-data-table-component';
import LeadlistApiCall from 'src/utils/LeadlistApiCall';
import LeadApiCall from 'src/utils/LeadApiCall';
import AddLeadForm from "./AddLeadForm";
import LeadImport from "./LeadImport";
import LeadTable from "./LeadTable";

const LeadlistDetail = ({ setLeadList }) => {
  const { leadlistId } = useParams();
  const [loading, setLoading] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleAddLead = async (leadData) => {
    const response = await LeadApiCall.createLead(leadlistId, leadData);
    // console.log(response);
    setRefreshTable((prev) => !prev);
    return response;
  };

  useEffect(() => {
    const fetchLeadLists = async () => {
      setLoading(true);
      const response = await LeadlistApiCall.getLeadlistById(leadlistId);
      if (response.status === 200) {
        setLeadList(response.leadlist);
      } else {
        console.error('Failed to fetch leads');
      }
      setLoading(false);
    };

    fetchLeadLists();
  }, [leadlistId]);

  const columns = [
    { name: 'Lead Name', selector: (row) => row.name, sortable: true },
    { name: 'Email', selector: (row) => row.email, sortable: true },
    { name: 'Phone', selector: (row) => row.phone, sortable: true },
  ];

  return (
    <Paper style={{ padding: 20 }}>
      {/* Title and Buttons aligned horizontally */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Leads in this List</Typography>
        <Box display="flex">
          <Box mr={2}>
            <AddLeadForm onSubmit={handleAddLead} />
          </Box>
          <LeadImport leadlistId={leadlistId} setRefreshTable={setRefreshTable} />
        </Box>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        // <DataTable
        //   columns={columns}
        //   data={[]} // Provide leads array here
        //   pagination
        //   highlightOnHover
        // />
        <LeadTable refreshTable={refreshTable} />
      )}
    </Paper>
  );
};

export default LeadlistDetail;
