/* eslint-disable */
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  IconButton,
  Dialog,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Icon,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Add, Edit, Delete } from "@material-ui/icons";
import { Pagination } from "@mui/material";
import CreateLeadlistForm from './CreateLeadlistForm';
import LeadlistTable from './LeadlistTable';
import SalesFunnelStepper from './SalesFunnelStepper';

const useStyles = makeStyles({
    list: {
      padding: 10,
    },
});
  
const LMS = () => {
  const classes = useStyles();
  const [lists, setLists] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const handleClose = () => {
    console.log("called");
    setDialogOpen(false);
    setRefreshTable((prev) => !prev);
  };

  return (
    <Card className={classes.list}>
      {/* <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Lead Management System</Typography>
        </Toolbar>
      </AppBar> */}

      <div style={{ marginTop: 20, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Lead List</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => setDialogOpen(true)}
        >
          Create List
        </Button>
      </div>
      <LeadlistTable refreshTable={refreshTable} setRefreshTable={setRefreshTable} />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        {/* <Container style={{ padding: 10 }}> */}
          {/* <Typography variant="h6">Create a New List</Typography> */}
          <CreateLeadlistForm handleClose={handleClose} />
          {/* </Container> */}
      </Dialog>
      <SalesFunnelStepper />
    </Card>
  );
};

export default LMS;
