import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {
  TextField, Paper, CircularProgress, IconButton, Tooltip, Select, MenuItem
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LeadApiCall from 'src/utils/LeadApiCall';
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from 'src/components/DataTable/ThemeDatatable';
import LeadDetailsDrawer from './LeadDetailsDrawer';

const LeadTable = ({ refreshTable }) => {
  const muiTheme = useTheme();
  const rdtTheme = getRdtTheme(muiTheme.palette.mode);
  const { leadlistId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // const limit = 10;
  const pageFromUrl = parseInt(searchParams.get('page'), 10) || 1;
  const perPageFromUrl = parseInt(searchParams.get('perPage'), 10) || 10;

  const [page, setPage] = useState(pageFromUrl);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [selectedLead, setSelectedLead] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [perPage, setPerPage] = useState(perPageFromUrl);

  const handleViewClick = (lead) => {
    setSelectedLead(lead);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (!leadlistId) return;

    const fetchLeads = async () => {
      setLoading(true);
      const skip = (page - 1) * perPage;
      const response = await LeadApiCall.getAllLeads({
        leadlistId, skip, limit: perPage, searchQuery
      });

      if (response.status === 200) {
        setLeads(response.leads);
        setTotalRows(response.totalRecords);
      } else {
        console.error('Failed to fetch leads');
      }
      setLoading(false);
    };

    fetchLeads();
  }, [leadlistId, page, perPage, searchQuery, refreshTable]);

  // Update URL when page changes
  useEffect(() => {
    setSearchParams({ page, perPage });
  }, [page, perPage]);

  const columns = [
    { name: 'Name', selector: (row) => row.name || 'N/A', sortable: true },
    { name: 'Phone', selector: (row) => row.phone, sortable: true },
    { name: 'City', selector: (row) => row.city || 'N/A', sortable: true },
    { name: 'Status', selector: (row) => row.leadStatus, sortable: true },
    {
      name: 'Action',
      cell: (row) => (
        <Tooltip title="View Details">
          <IconButton onClick={() => handleViewClick(row)} color="primary">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
      width: '100px',
    },
  ];

  return (
    <Paper style={{ padding: '20px', marginTop: '20px' }}>
      {/* Search Box */}
      <TextField
        label="Search Leads"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        <>
          <div style={{
            marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px'
          }}
          >
            <span>Rows per page:</span>
            <Select value={perPage} onChange={(e) => setPerPage(Number(e.target.value))}>
              {[10, 20, 30, 50, 100].map((size) => (
                <MenuItem key={size} value={size}>{size}</MenuItem>
              ))}
            </Select>
          </div>
          <DataTable
            columns={columns}
            data={leads}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            customStyles={{}}
            theme={rdtTheme}
            paginationDefaultPage={page}
            onChangePage={(newPage) => setPage(newPage)} // Update page state
            highlightOnHover
            responsive
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]} // ✅ Available options
            // onChangeRowsPerPage={(newPerPage) => setPerPage(newPerPage)}
            onChangeRowsPerPage={(newPerPage, newPage) => {
              setPerPage(newPerPage);
              setPage(newPage); // Optional: Reset page to 1 when perPage changes
              // setSearchParams({ perPage: newPerPage, page: newPage }); // ✅ Update URL
            }}
          />
        </>
      )}
      {/* Lead Details Drawer */}
      <LeadDetailsDrawer lead={selectedLead} open={drawerOpen} onClose={handleCloseDrawer} />
    </Paper>
  );
};

// ✅ PropTypes Validation
LeadTable.propTypes = {
  refreshTable: PropTypes.bool.isRequired,
};

export default LeadTable;
