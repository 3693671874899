/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import ReactPlayer from 'react-player';
// core components
import Header from "src/components/Header/Header.js";
import Footer from "src/components/Footer/Footer.js";
import Button from "src/components/CustomButtons/Button.js";
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import HeaderLinks from "src/components/Header/HeaderLinks.js";
import NavPills from "src/components/NavPills/NavPills.js";
import Parallax from "src/components/Parallax/Parallax.js";

import profile from "src/assets/img/faces/christian.jpg";
import BusinessIcon from '@material-ui/icons/Business';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import studio1 from "src/assets/img/examples/studio-1.jpg";
import studio2 from "src/assets/img/examples/studio-2.jpg";
import studio3 from "src/assets/img/examples/studio-3.jpg";
import studio4 from "src/assets/img/examples/studio-4.jpg";
import studio5 from "src/assets/img/examples/studio-5.jpg";
import business1 from 'src/assets/img/solutions/biz1.jpeg';
import business2 from 'src/assets/img/solutions/busy2.jpeg';
import business3 from 'src/assets/img/solutions/busy3.jpeg';
import business4 from 'src/assets/img/solutions/business-training-consulting-service-icons-set-vector.jpeg';
import business5 from 'src/assets/img/solutions/business-consulting-png.png';
import business6 from 'src/assets/img/solutions/project-management.jpeg';

import task1 from 'src/assets/img/solutions/taskm1.jpeg';
import task2 from 'src/assets/img/solutions/task2.jpeg';
import task3 from 'src/assets/img/solutions/task3.jpeg';
import task4 from 'src/assets/img/solutions/task-4.jpeg';
import task5 from 'src/assets/img/solutions/task5.jpeg';

// import form from 'src/assets/img/solutions/task6.jpeg';
import tasks from 'src/assets/img/solutions/tasks.jpeg';
import mobile from 'src/assets/img/solutions/mobile.jpeg';
import training from 'src/assets/img/solutions/training.jpeg';
import dashboard from 'src/assets/img/solutions/dashboard.jpeg';
import bmsintro from 'src/assets/img/solutions/bms-intro.jpeg';
import Product from './ProductSection';
import banner from 'src/assets/img/solutions/virtual-office.jpeg';
import styles from "src/assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="BMS GLOBAL"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax normal filter image={banner} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    {/* <img src={profile} alt="..." className={imageClasses} /> */}
                    <h3 className={classes.title}>Learn & Teach From anywhere</h3>
                  </div>
                  <div className={classes.name}>
                    {/* <h3 className={classes.title}>Christian Louboutin</h3>
                    <h6>DESIGNER</h6> */}
                    
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                BMS GLOBAL app is the business management system, first time of its kind. Here is your virtual
                office available, here You can manage the 
                business stuff with complete ease. {" "}
              </p>
            </div>
            <GridContainer justifyContent="center">
              {/* <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Business",
                      tabIcon: BusinessIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={business1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={business2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={business3}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={business4}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={business5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={business6}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Management",
                      tabIcon: LocalLibraryIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={task1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={task2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={task3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={task4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={task5}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "System",
                      tabIcon: MergeTypeIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={mobile}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={training}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={dashboard}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={tasks}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem> */}
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper} spacing={0}>
                <div>
                  <h3 className={classes.title}>Starting your business?</h3>
                </div>
                <div>
                  <h3 className={classes.title}>Difficulties in team handling?</h3>
                </div>
                <div>
                  <h3 className={classes.title}>Unable to trained your employees/team well?</h3>
                </div>
                <div>
                  <h3 className={classes.title}>Here is your Virtual Office with BMS GLOBAL.</h3>
                </div>
                {/* <GridContainer justifyContent="center">
                  <ReactPlayer
                    width="100%"
                    height="365px"
                    controls={!false}
                    url="https://youtu.be/pa4SXL52mTg"
                  />
                </GridContainer> */}
                <GridContainer justifyContent="center">
                    <Product />
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
