/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import axios from 'axios';
import Page from 'src/components/Page';
// import { v4 as uuid } from 'uuid';
// import Confetti from 'src/components/Confetti/Confetti';
import GroupMemberApiCall from 'src/utils/GroupMemberApiCall';
import MuiAlert from '@material-ui/lab/Alert';

import Training from './Training';
import TotalApplications from './TotalApplications';
// import LatestOrders from './LatestOrders';
// import LatestProducts from './LatestProducts';
import Ppts from './Ppts';
import LiveTrainings from './LiveTrainings';
import TasksProgress from './TasksProgress';
import AssignedTasks from './AssignedTasks';
import TotalCustomers from './TotalCustomers';
import TotalDsreports from './TotalDsreports';
import TakePass from './TakePass';
import Groups from './Groups';

// import TrafficByDevice from './TrafficByDevice';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [dashboardData, setDashboardData] = useState([]);
  const [mygroups, setMygroups] = useState([]);
  
  const dsrPermissions = localStorage.getItem('dsr_permissions') ? JSON.parse(localStorage.getItem('dsr_permissions')) : {};
  const hasPermissionForDsrViewer = Boolean(
    ['paid_user', 'admin'].includes(getLoggedinUserData.role) || 
    dsrPermissions?.read
  );
  const fetchDashboardData = async () => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/my-dashboard`, options)
      .then((res) => {
        setDashboardData(res.data.dashboardData);
        // console.log(dashboardData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchMygroups = async () => {
    try {
      const resp = await GroupMemberApiCall.myGroups();
      console.log('group member created', resp);
      if (resp.status === 200 && resp.mygroups) {
        setMygroups(resp.mygroups);
        console.log(resp.mygroups);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      // setSubmitting(false);
    }
  };

  useEffect(() => {
    // Confetti.runConfetti();
    fetchDashboardData();
    if (getLoggedinUserData.role !== 'guest') {
      fetchMygroups();
    }
    ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'Dashboard', 'Dancing on Dashboard.');
  }, []);
  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {!getLoggedinUserData.email && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Alert severity="warning">
                Email id is not updated yet. Update Your Email Id! &nbsp;
                <RouterLink to="/app/account">
                  Click here
                </RouterLink>
              </Alert>
            </Grid>
          )}
          {['learner', 'paid_user', 'admin', 'bms_promotor'].includes(getLoggedinUserData.role) && (mygroups.length > 0)
            && (
            <Grid
              item
              lg={3}
              md={6}
              xl={3}
              xs={12}
            >
              <Groups mygroups={mygroups} />
            </Grid>
            )}
          {getLoggedinUserData.role === 'user'
          && (
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RouterLink to={getLoggedinUserData.role !== 'user' ? '/app/tasks' : '#'}>
                <TakePass />
              </RouterLink>
            </Grid>
          )}
          {['learner', 'admin', 'paid_user', 'bms_promotor'].includes(getLoggedinUserData.role)
          && (
          <>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RouterLink to={['admin', 'paid_user', 'bms_promotor'].includes(getLoggedinUserData.role) ? '/app/work-application/list' : '#'}>
                <TotalApplications
                  totalApplications={dashboardData && dashboardData.totalApplications}
                />
              </RouterLink>
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RouterLink to={['admin', 'paid_user', 'bms_promotor'].includes(getLoggedinUserData.role) ? '/app/tcategories' : '#'}>
                <Training trainingsCount={dashboardData && dashboardData.trainingsCount} />
              </RouterLink>
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RouterLink to={['admin', 'paid_user', 'bms_promotor'].includes(getLoggedinUserData.role) ? '/app/customers' : '#'}>
                <TotalCustomers totalCustomers={dashboardData && dashboardData.downlinesCount} />
              </RouterLink>
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RouterLink to={['admin', 'paid_user', 'bms_promotor'].includes(getLoggedinUserData.role) ? '/app/tasks' : '#'}>
                <TasksProgress totalTasks={dashboardData && dashboardData.tasksCount} />
              </RouterLink>
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RouterLink to={['admin', 'paid_user', 'bms_promotor'].includes(getLoggedinUserData.role) ? '/app/dailyReports' : '#'}>
                <TotalDsreports dsreportsCount={dashboardData && dashboardData.dsreportsCount} />
              </RouterLink>
            </Grid>
          </>
          )}
          {
          ['learner', 'paid_user', 'admin', 'bms_promotor'].includes(getLoggedinUserData.role)
            && (
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RouterLink to="/app/tasks">
                <AssignedTasks
                  tasksAssignedCount={dashboardData && dashboardData.tasksAssignedCount}
                />
              </RouterLink>
            </Grid>
            )
          }
          {
            (hasPermissionForDsrViewer)
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Ppts userId={getLoggedinUserData.id} />
              </Grid>
            )
          }
          {
            (hasPermissionForDsrViewer)
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <LiveTrainings />
              </Grid>
            )
          }
          {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice />
          </Grid> */}
          {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid> */}
          {/* <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
