import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect, useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
// import theme from 'src/theme';
import { lightTheme, darkTheme } from 'src/theme';
import routes from 'src/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ReactGA from 'react-ga';
// import { clarity } from 'react-microsoft-clarity';
import TrackPageViews from './TrackPageViews';
// import { FullScreen, useFullScreenHandle } from 'react-full-screen';
// import ContentLoader, { Facebook } from 'react-content-loader';
// import ThemeSwitcherDrawer from './components/ThemeSwitcherDrawer';
import ThemeSwitcher from './components/ThemeSwitcher';

import AppContext from './components/AppContext';
import UserContext from './contexts/UserContext';

const App = () => {
  // const handle = useFullScreenHandle();
  // const { isLoggedIn } = useSelector((state) => state.auth);
  // async function checkAuthOuter() {
  //   await inLocalStorageJwt.checkAuth().then((res) => {
  //     console.log(res);
  //     return res;
  //   });
  // }
  // const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  // const MyLoader = () => (
  //   <ContentLoader>
  //     <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
  //     <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
  //     <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  //   </ContentLoader>
  // );
  // const MyFacebookLoader = () => <Facebook />;
  // clarity.init('fpaj5v4tyx');
  const [theme, setTheme] = useState(lightTheme);
  const toggleTheme = (newTheme) => {
    if (newTheme === 'light') {
      setTheme(lightTheme);
    } else if (newTheme === 'dark') {
      setTheme(darkTheme);
    }
    // setTheme((prevTheme) => (prevTheme === lightTheme ? darkTheme : lightTheme));
  };
  ReactGA.initialize('G-MH1BE43P25');
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [routesob, setRoutesob] = useState([]);
  const userContext = useContext(UserContext);
  const updateAuth = (flag) => {
    setIsAuthenticated(flag);
    // console.log('frst call', isAuthenticated);
    return true;
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    async function checkAuth() {
      const response = await inLocalStorageJwt.checkAuth();
      // console.log('hello there', response);
      setIsAuthenticated(response);
      // console.log(islogged);
      // ...
    }
    userContext.userData = inLocalStorageJwt.getLoggedinUserData();
    checkAuth();
    console.log('first effect');
    console.log(userContext);
    const tokenExpiryCheckInterval = setInterval(checkAuth, 60000); // Check every minute

    return () => {
      clearInterval(tokenExpiryCheckInterval);
    };
  }, []); // Or [] if effect doesn't need props or state

  useEffect(() => {
    console.log('after change outer', isAuthenticated);
    if (isAuthenticated === false) {
      userContext.userData = {};
    } else {
      userContext.userData = inLocalStorageJwt.getLoggedinUserData();
    }
    const userD = userContext.userData;
    // alert(`is auth check in app ${userData.name}  ${isAuthenticated}`);
    if (isAuthenticated === true && (!userD.name || userD.name === 'undefined' || userD.name === 'undefined undefined')) {
      inLocalStorageJwt.logout();
    }
    if (typeof isAuthenticated !== 'undefined') {
      // console.log('after change inner', userData);
      setRoutesob(routes(isAuthenticated, userD));
    }
    // console.log('2nd effect');
    // console.log(userContext);
  }, [isAuthenticated]); // Or [] if effect doesn't need props or state

  useEffect(() => {
    // if (!isAuthenticated) {
    //   userContext.userData = {};
    // }
    // console.log('after change outer', isAuthenticated);
    // alert(userContext.userData.name);
    // alert(`is 2 check in app ${userContext.userData.name}  ${isAuthenticated}`);
    if (isAuthenticated === true && (!userContext.userData.name || userContext.userData.name === 'undefined' || userContext.userData.name === 'undefined undefined')) {
      inLocalStorageJwt.logout();
    }
    if (typeof isAuthenticated !== 'undefined') {
      // alert(isAuthenticated);
      setRoutesob(routes(isAuthenticated, userContext.userData));
    }
    // console.log('2nd effect');
    // console.log(userContext);
  }, [userContext.userData]); // Or [] if effect doesn't need props or state

  const routing = useRoutes(routesob);
  // App.js continued
  const appContextVal = {
    updateAuth,
  };
  return (
    <AppContext.Provider value={appContextVal}>
      <ThemeProvider theme={theme}>
        {/* <button type="button" onClick={handle.enter}>
          Enter fullscreen
        </button>
        <FullScreen handle={handle}>
          Any fullscreen content here
        </FullScreen> */}
        <TrackPageViews />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <GlobalStyles />
        {routing}
        {/* <ThemeSwitcherDrawer toggleTheme={toggleTheme} /> */}
        <ThemeSwitcher toggleTheme={toggleTheme} theme={theme} />
        {/* 🔹 Theme Switcher Button */}
        {/* <>
          <MyLoader />
          <MyFacebookLoader />
        </> */}
        {/* All other components are wrapped by the AppContext.Provider */}
      </ThemeProvider>
    </AppContext.Provider>

  );
};

export default App;
