/* eslint-disable */
import React from 'react';
// import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { spacing } from '@material-ui/system';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        BMSGLOBAL
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  policyContent: {
      marginTop:'10px',
      marginLeft: '10px',
      marginRight: '5px'
  }
}));

export default function Pricing() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Return-Refund Policy (Subscription)
        </Typography>
        {/* <Typography variant="h5" align="center" color="textSecondary" component="p">
          Quickly build an effective pricing table for your potential customers with this layout.
          It&apos;s built with default Material-UI components with little customization.
        </Typography> */}
      </Container>
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Typography className={classes.policyContent} component="h1" variant="h2" align="left" color="textPrimary" gutterBottom>
          1. Introduction
        </Typography>
        <Divider />
        <Typography className={classes.policyContent} variant="h5" align="left" color="textSecondary" component="p">
          This Subscription Policy applies to all the paid products, services, websites and apps offered by bmsglobal.me and their affiliates (collectively "BMS GLOBAL"). We refer to those products, services, websites and apps collectively as the "services" in this policy.
        </Typography>
        <Typography className={classes.policyContent} component="h1" variant="h2" align="left" color="textPrimary" gutterBottom>
        2. Subscription
        </Typography>
        <Divider />
        <Typography className={classes.policyContent} variant="h5" align="left" color="textSecondary" component="p">
          If you are using our products and services and paying for it periodically then you are using our subscription plans.
        </Typography>
        <Typography className={classes.policyContent} component="h2" variant="h3" align="left" color="textPrimary" gutterBottom>
        2.1 How can I avail the subscription?
        </Typography>
        <Typography className={classes.policyContent} variant="h5" align="left" color="textSecondary" component="p">
        1. You can choose the plan from "pricing" page according to your need of business. <br />
        2. complete your payment online and enjoy the subscription for particular date and time.<br />
        </Typography>

        <Typography className={classes.policyContent} component="h2" variant="h3" align="left" color="textPrimary" gutterBottom>
        2.2 Can I cancel the subscription?
        </Typography>
        <Typography className={classes.policyContent} variant="h5" align="left" color="textSecondary" component="p">
        Yes sure, you can cancel your subscription any time you want, and further, we will not charge without your permission.
        </Typography>
        <Typography className={classes.policyContent} component="h2" variant="h3" align="left" color="textPrimary" gutterBottom>
        2.3 Is there any refund on cancellation?
        </Typography>
        <Typography className={classes.policyContent} variant="h5" align="left" color="textSecondary" component="p">
         Here is the use case: <br />
         1. if you have mistakenly bought the subscription and not used the paid services yet, you will get the refund on your cancellation excluding the taxes and charges on your purchase, within the 7 days of your subscription only.<br />
         2. If you have bought the subscription and start using it, there is no refund on cancellation.<br />
         3. There is no refund after 15 days of subscription.
        </Typography>
        <Typography className={classes.policyContent} component="h2" variant="h3" align="left" color="textPrimary" gutterBottom>
        Thank You!
        </Typography>
      </Container>
      {/* End hero unit */}
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </>
  );
}
