import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import axios from 'axios';
import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Button,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  capitalize
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import { toast } from 'react-toastify';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import {
  Eye as ViewIcon,
  Copy as CopyIcon
} from 'react-feather';

import { useNavigate } from 'react-router-dom';
import TrainingContext from 'src/contexts/TrainingContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { isMobile } from 'react-device-detect';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.mode === 'dark' ? '#FFD700' : '#4051B5',
  },
  dropdown: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
    padding: theme.spacing(2)
  }
}));
// axios.all([
//   axios.get('https://api.github.com/users/hacktivist123'),
//   axios.get('https://api.github.com/users/adenekan41')
// ])
//   .then((response) => {
//     console.log('Date created: ', response[0].data.created_at);
//     console.log('Date created: ', response[1].data.created_at);
//   });
const ProductCard = ({
  className, training, paidUsers, ...rest
}) => {
  let permissions = null;
  if (training.data) {
    permissions = {
      tId: training.data.id,
      edit: training.edit,
      delete: training.delete,
      view: training.view,
      share: training.share,
      visibility: training.data.visibility
    };
  } else {
    permissions = {
      tId: training.id,
      edit: true,
      delete: true,
      view: true,
      share: true,
      visibility: training.visibility
    };
  }
  const classes = useStyles();
  const navigate = useNavigate();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const trainingContext = useContext(TrainingContext);
  const [openTraining, setOpenTraining] = React.useState(false);

  const handleTrainingClick = () => {
    setOpenTraining(!openTraining);
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  /* Delete training function. */
  function deleteTraining(trainingId) {
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/trainings/delete/${trainingId}`, options)
      .then((resp) => {
        // console.log(resp);
        trainingContext.fetchTrainings();
        toast.success(`${resp.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }
  const [copySuccess, setCopySuccess] = useState('');

  async function handleCopy(trainingId) {
    // console.log(trainingId, permissions);
    const currentDate = new Date(); // Current date and time
    const next12Hours = new Date(currentDate.getTime() + 12 * 60 * 60 * 1000); // Adding 12 hours
    const originalData = `?premiere=false&explink=${next12Hours.toISOString()}`;
    const secretKey = process.env.REACT_APP_ENC_SECRET_KEY;
    const encodedData = inLocalStorageJwt.encodeWithAES(originalData, secretKey);
    // const decodedData = inLocalStorageJwt.decodeWithAES(encodedData, secretKey);
    // console.log(decodedData);
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/training/details-page/${trainingId}?type=${encodedData}`);
    setCopySuccess(' Copied!');
  }

  async function handleNoAuthCopy(trainingId) {
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/training/pubview/${trainingId}`);
    setCopySuccess(' Copied!');
  }
  async function handleCopyInvitation(trainingId) {
    // console.log(trainingId, permissions);
    const currentDate = new Date(); // Current date and time
    const next12Hours = new Date(currentDate.getTime() + 12 * 60 * 60 * 1000); // Adding 12 hours
    const originalData = `?premiere=false&explink=${next12Hours.toISOString()}`;
    const secretKey = process.env.REACT_APP_ENC_SECRET_KEY;
    const encodedData = inLocalStorageJwt.encodeWithAES(originalData, secretKey);
    // const decodedData = inLocalStorageJwt.decodeWithAES(encodedData, secretKey);
    // console.log(decodedData);
    let textToCopy = `Dear Candidate, Here is the training details!\n\nTraining Name: ${training.data ? training.data.name : training.name}\n\n`;
    textToCopy += `Trainer: ${training.data ? training.data.trainer : training.trainer}\n\n`;
    textToCopy += `Topic: ${training.data ? training.data.topic : training.topic}\n\n`;
    textToCopy += `Note: ${training.data ? training.data.notes : training.notes}\n\n`;
    textToCopy += `Join Meeting: \n ${process.env.REACT_APP_BASE_URL}/training/details-page/${trainingId}?type=${encodedData}\n`;
    navigator.clipboard.writeText(textToCopy);
    setCopySuccess(' Copied!');
  }
  function handleDelete(trainingId) {
    // console.log('id', trainingId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); deleteTraining(trainingId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  function handleViewDetail(trainingId) {
    navigate(`/app/training/details-page/${trainingId}`);
  }
  const [open, setOpen] = React.useState(false);
  const [openPremiere, setOpenPremiere] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPremiere = () => {
    setOpenPremiere(true);
  };
  const handleClosePremiere = () => {
    setOpenPremiere(false);
  };
  // function matchYoutubeUrl(url) {
  //   const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/
  // |youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  //   if (url.match(p)) {
  //     return url.match(p)[1];
  //   }
  //   return false;
  // }
  return (
    <>
      <ListItem className={classes.dropdown} button onClick={handleTrainingClick}>
        <ListItemIcon>
          <OndemandVideoIcon className={classes.statsIcon} />
        </ListItemIcon>
        <ListItemText
          primary={`${`${capitalize(training.data ? training.data.name : training.name)} | ${capitalize(training.data ? training.data.trainer : training.trainer)}`}`}
        />
        {openTraining ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openTraining} timeout="auto" unmountOnExit>
        {/* <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Starred" />
          </ListItem>
        </List> */}
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <div className="player-wrapper">
            <ReactPlayer
              width="100%"
              height="365px"
              // light="/static/images/video-thumbnail.jpg"
              onContextMenu={(e) => { e.preventDefault(); }}
              muted={!false}
              controls={!false}
              url={training.data ? training.data.video_link : training.video_link}
              // url="https://bmsglobal.s3.ap-south-1.amazonaws.com/uploads/trainings/Introductory+Session.mp4"
              config={{
                file: { attributes: { controlsList: 'nodownload' } },
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    enablejsapi: 0,
                    origin: process.env.REACT_APP_BASE_URL
                  },
                  events: {
                    onStateChange: () => { console.log('hhhh'); }
                  }
                }
              }}
            />
          </div>
          <CardContent>
            {/* <Box
              display="flex"
              justifyContent="center"
              mb={3}
            >
              <Avatar
                alt="Thumbnail"
                src={process.env.REACT_APP_SERVER_BASE_URL + (training.data
                  ? training.data.thumbnail : training.thumbnail)}
                variant="square"
              />
            </Box> */}
            {/* <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {training.data ? training.data.name : training.name}
            </Typography> */}
            {/* <div className="player-wrapper">
              <ReactPlayer
                width="100%"
                height="365px"
                controls={!false}
                url={training.data ? training.data.video_link : training.video_link}
              />
            </div> */}
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              {`By ${training.data ? training.data.trainer : training.trainer}`}
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              {training.data ? training.data.notes : training.notes}
            </Typography>
          </CardContent>
          <Box flexGrow={1} />
          <Divider />
          <Box p={2}>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
            >
              {
                permissions && permissions.edit === true
                && (
                <Grid
                  className={classes.statsItem}
                  item
                >
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    <Button onClick={() => navigate(`/app/training/update?trainingid=${training.data ? training.data.id : training.id}`)}>
                      <EditIcon
                        className={classes.statsIcon}
                        color="action"
                        // style={{ color: '#4051B5' }}
                      />
                      Edit
                    </Button>
                  </Typography>
                </Grid>
                )
              }
              {
                permissions && permissions.share === true
                && (
                <Grid
                  className={classes.statsItem}
                  item
                >
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    <Button onClick={() => handleClickOpen(training)}>
                      <ShareIcon
                        className={classes.statsIcon}
                        color="action"
                        // style={{ color: '#4051B5' }}
                      />
                      Share
                    </Button>
                  </Typography>
                </Grid>
                )
              }
              <Grid
                className={classes.statsItem}
                item
              >
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                >
                  <Button
                    onClick={() => handleViewDetail(permissions.tId)}
                    // style={{ color: '#4051B5' }}
                  >
                    <ViewIcon
                      className={classes.statsIcon}
                      // style={{ color: '#4051B5' }}
                    />
                    {' '}
                    View
                  </Button>
                </Typography>
              </Grid>
              {
                permissions && permissions.visibility !== 'private'
                && (
                <>
                  <Grid
                    className={classes.statsItem}
                    item
                  >
                    <Typography
                      color="textSecondary"
                      display="inline"
                      variant="body2"
                    >
                      <Button
                        onClick={() => handleCopy(permissions.tId)}
                      >
                        <CopyIcon
                          className={classes.statsIcon}
                        />
                        Copy Link
                      </Button>
                      {copySuccess}
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.statsItem}
                    item
                  >
                    <Typography
                      color="textSecondary"
                      display="inline"
                      variant="body2"
                    >
                      <Button
                        onClick={() => handleNoAuthCopy(permissions.tId)}
                        // style={{ color: '#4051B5' }}
                      >
                        <CopyIcon
                          className={classes.statsIcon}
                          // style={{ color: '#4051B5' }}
                        />
                        Copy Link (No Login Required)
                      </Button>
                      {copySuccess}
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.statsItem}
                    item
                  >
                    <Typography
                      color="textSecondary"
                      display="inline"
                      variant="body2"
                    >
                      <Button
                        onClick={() => handleCopyInvitation(permissions.tId)}
                        // style={{ color: '#4051B5' }}
                      >
                        <CopyIcon
                          className={classes.statsIcon}
                          // style={{ color: '#4051B5' }}
                        />
                        Copy Invitation
                      </Button>
                      {copySuccess}
                    </Typography>
                  </Grid>
                </>
                )
              }
              {
                permissions && permissions.delete === true
                && (
                <Grid
                  className={classes.statsItem}
                  item
                >
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    <Button
                      onClick={() => handleDelete(permissions.tId)}
                      style={{ color: '#ff6666' }}
                    >
                      <DeleteIcon
                        className={classes.statsIcon}
                        color="action"
                        style={{ color: '#ff6666' }}
                      />
                      Delete
                    </Button>
                  </Typography>
                </Grid>
                )
              }
              {
                permissions && permissions.delete === true
                && (
                <Grid
                  className={classes.statsItem}
                  item
                >
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    <Button
                      onClick={() => handleClickOpenPremiere(training)}
                      // style={{ color: '#4051B5' }}
                    >
                      <LiveTvIcon
                        className={classes.statsIcon}
                        color="action"
                        // style={{ color: '#4051B5' }}
                      />
                      Create Premiere
                    </Button>
                  </Typography>
                </Grid>
                )
              }
            </Grid>
          </Box>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-format="fluid"
            data-ad-layout-key="-f3-d+1d-41+70"
            data-ad-client="ca-pub-4645266536536108"
            data-ad-slot="6734199001"
          />
          <script>
            (adsbygoogle = window.adsbygoogle || []).push(
            {}
            );
          </script>
        </Card>
      </Collapse>
      {/* Starting Code for share dialog box */}
      <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <Formik
            initialValues={{
              user: '',
              dataType: 'Training',
              data: training.data ? training.data.id : training.id,
              view: true,
              edit: false,
              delete: false,
              share: false
            }}
            validationSchema={Yup.object().shape({
              user: Yup.string().required('User is required'),
              view: Yup.boolean().required('View is required')
            })}
            onSubmit={(values, { setSubmitting }) => {
              const postData = {
                user: values.user,
                dataType: values.dataType,
                data: values.data,
                view: values.view,
                edit: values.edit,
                delete: values.delete,
                share: values.share,
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/shared-data/create`, postData, options)
                .then((res) => {
                  // const respData = res.data.sdata;
                  // alert(respData.tokens.access);
                  toast.success(`${res.data.message}`, {
                    position: 'top-right',
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  handleClose();
                  // if (res.data.status === true) {
                  //   // navigate('/app/dashboard', { replace: true });
                  // }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
              // setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">Share Training (Bydefault user can view)</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {`Share ${training.data ? training.data.name : training.name} with your downlines.`}
                  </DialogContentText>
                  <TextField
                    error={Boolean(touched.user && errors.user)}
                    helperText={touched.user && errors.user}
                    fullWidth
                    // label="Select User"
                    name="user"
                    // onClick={handleCountryClick}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    SelectProps={{ native: true }}
                    value={values.user}
                    variant="outlined"
                  >
                    <option value="" key="">Select User</option>
                    {paidUsers.map((paidUser) => (
                      <option
                        key={paidUser.id}
                        value={paidUser.id}
                      >
                        {paidUser.fname}
                      </option>
                    ))}
                  </TextField>
                  {/* <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={values.view}
                          onChange={handleChange}
                          name="view"
                          color="primary"
                        />
                      )}
                      label="View"
                    />
                  </Grid> */}
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={values.share}
                          onChange={handleChange}
                          name="share"
                          color="primary"
                        />
                      )}
                      label="can share"
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Share
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      </div>
      {/* End Code for share dialog box */}

      {/* Starting Code for Creating Premiere dialog box */}
      <div>
        <Dialog open={openPremiere} onClose={handleClosePremiere} aria-labelledby="form-dialog-title">
          <Formik
            initialValues={{
              trainingid: training.data ? training.data.id : training.id,
              prem_time: '',
            }}
            validationSchema={Yup.object().shape({
              prem_time: Yup.date().required('Premiere time is required')
            })}
            onSubmit={(values, { setSubmitting }) => {
              const postData = {
                training: values.trainingid,
                prem_time: values.prem_time,
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/premieres/create`, postData, options)
                .then((res) => {
                  // const respData = res.data.sdata;
                  // alert(respData.tokens.access);
                  toast.success(`${res.data.message}`, {
                    position: 'top-right',
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  handleClosePremiere();
                  navigate(`/app/training/details-page/${training.id}`);

                  // if (res.data.status === true) {
                  //   // navigate('/app/dashboard', { replace: true });
                  // }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              // handleBlur,
              // handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">Create Premiere</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>
                    {`Create Premiere of ${training.data ? training.data.name : training.name}.`}
                  </DialogContentText> */}
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        disablePast
                        onChange={(date) => {
                          setFieldValue('prem_time', date, errors.deadline);
                        }}
                        error={Boolean(touched.prem_time && errors.prem_time)}
                        helperText={touched.prem_time && errors.prem_time}
                        fullWidth
                        label="Premiere time"
                        name="prem_time"
                        required
                        value={values.prem_time}
                        variant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {/* <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={values.share}
                          onChange={handleChange}
                          name="share"
                          color="primary"
                        />
                      )}
                      label="can share"
                    />
                  </Grid> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClosePremiere} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Create
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      </div>
      {/* End Code for Creating Premiere dialog box */}
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  training: PropTypes.object.isRequired,
  paidUsers: PropTypes.array.isRequired
};

export default ProductCard;
