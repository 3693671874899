import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Typography, ButtonBase } from '@mui/material';

const ThemeSwitcher = ({ toggleTheme, theme }) => {
  const isDarkMode = theme.palette.mode === 'dark';
  const [position, setPosition] = useState({
    x: window.innerWidth - 80, y: window.innerHeight * 0.2
  });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  return (
    <ButtonBase
      onClick={() => toggleTheme(isDarkMode ? 'light' : 'dark')}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      sx={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        p: 1,
        borderRadius: '2xl',
        bgcolor: isDarkMode ? 'grey.900' : 'grey.200',
        transition: 'background-color 0.3s ease-in-out',
        cursor: 'grab',
        '&:hover': {
          bgcolor: isDarkMode ? 'grey.800' : 'grey.300',
        },
      }}
    >
      <Typography variant="body2">🌞</Typography>
      <Switch
        checked={isDarkMode}
        sx={{
          '& .MuiSwitch-thumb': { bgcolor: isDarkMode ? 'white' : 'black' },
          '& .MuiSwitch-track': { bgcolor: isDarkMode ? 'grey.800' : 'grey.400' },
        }}
      />
      <Typography variant="body2">🌙</Typography>
    </ButtonBase>
  );
};

ThemeSwitcher.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      mode: PropTypes.oneOf(['light', 'dark']).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ThemeSwitcher;
