import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stepper, Step, StepLabel, Button, Paper, Typography
} from '@material-ui/core';

const steps = [
  'Lead Captured', 'Contact Initiated', 'Needs Identified', 'Proposal Sent', 'Negotiation',
  'Closed - Won', 'Closed - Lost', 'Follow-Up', 'Upsell Opportunity', 'Referral Asked'
];

const SalesFunnelStepper = ({ activeStepProp = 0 }) => {
  const [activeStep, setActiveStep] = useState(activeStepProp);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  return (
    <Paper style={{
      padding: 20, maxWidth: '90%', margin: 'auto', overflowX: 'auto'
    }}
    >
      <Typography variant="h6" align="center">Sales Funnel Progress</Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div style={{ marginTop: 20, textAlign: 'center' }}>
        <Button disabled={activeStep === 0} onClick={handleBack} color="secondary">
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext} style={{ marginLeft: 10 }}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </div>
    </Paper>
  );
};

SalesFunnelStepper.propTypes = {
  activeStepProp: PropTypes.number,
};

export default SalesFunnelStepper;
