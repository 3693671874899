/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
  Link,
  Button,
  Grid
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Visibility';
import Page from 'src/components/Page';
import ApiCall from 'src/utils/ApiCall';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import moment from 'moment';
import { toast } from 'react-toastify';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
/*
 * Code for Data table theme change dark & light
 **/
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from './ThemeDatatable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  }
}));

const List = (params) => {
  const { sponsor } = params;
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>First Name</b>,
      selector: 'fname',
      sortable: true,
    //   right: true,
    //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Last Name</b>,
      selector: 'lname',
      sortable: true,
      center: true,
    },
    {
        name: 'Action',
        center: true,
        // cell: (row) => <Link color="primary"
        // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
        cell: (row) => (
            <>
            <Link color="primary" size="small" component={RouterLink} to={`/app/customer/view/${row.id}`}>
                <IconButton color="primary" size="small" variant="contained">
                <ViewIcon />
                </IconButton>
            </Link>
            </>
        )
    }
  ];
  const fetchUsers = async (page) => {
    const offset = (page - 1) * perPage;
    setLoading(true);
    // const postData = {
    //   createdBy: getLoggedinUserData.id,
    //   skip: offset,
    //   limit: perPage
    // };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?bms_sponsor=${getLoggedinUserData.email}&skip=${offset}&limit=${perPage}`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        setData(res.data.result.users);
        setTotalRows(res.data.result.totalUsers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = async (page) => {
    // fetchUsers(page);
    const response = await ApiCall.fetchUsersForList(sponsor,perPage,page);
    setData(response.users);
    setTotalRows(response.totalUsers);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    // await fetchUsers(page);
    // setData(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      // You can await here
      if(!sponsor){
        return;
      }
      const response = await ApiCall.fetchUsersForList(sponsor,perPage,currentPage);
      // console.log(response);
      setData(response.users);
      setTotalRows(response.totalUsers);
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  useEffect(() => {
    async function fetchData() {
      // You can await here
      if(!sponsor){
        return;
      }
      const response = await ApiCall.fetchUsersForList(sponsor,perPage,currentPage);
      setData(response.users);
      setTotalRows(response.totalUsers);
      // ...
    }
    fetchData();
  }, [perPage]); // Or [] if effect doesn't need props or state

  const ExpandedRow = (row) => {
    // console.log(row);
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Email</Th>
                      <Th className={classes.respHeader}>Phone</Th>
                      <Th className={classes.respHeader}>Account Status</Th>
                      <Th className={classes.respHeader}>Type</Th>
                      {/* <Th className={classes.respHeader}>Notes</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{applicationOb.email}</Td>
                      <Td className={classes.respHeader}>{applicationOb.phone}</Td>
                      <Td className={classes.respHeader}>{ applicationOb.is_verified ? 'Verified'  : 'Not Verified' }</Td>
                      <Td className={classes.respHeader}>{applicationOb.role === 'paid_user'? 'Premium User': 'Free User'}</Td>
                      {/* <Td className={classes.respHeader}>{applicationOb.notes}</Td> */}
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
      <Container maxWidth={false}>
        <Box mt={3}>
          {/* <DsrList dsrList={dsrList} /> */}
          <DataTableExtensions {...tableData}>
            <DataTable
              title="Users List"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              filter
              paginationServer
              paginationTotalRows={totalRows}
              customStyles={{}}
              theme={rdtTheme} // Dynamically apply theme
              // selectableRows
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              expandableRows
              expandableRowDisabled={row => row.disabled}
              expandableRowsComponent={<ExpandedRow />}
            />
          </DataTableExtensions>
        </Box>
      </Container>
  );
};

export default List;
