/* eslint-disable */
// save token in localstorage
import axios from "axios";
// import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

// Function to encode using AES encryption
function encodeWithAES(data, secretKey) {
  const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
  return btoa(encrypted);
}

// Function to decode using AES decryption
function decodeWithAES(encodedData, secretKey) {
  const decrypted = CryptoJS.AES.decrypt(atob(encodedData), secretKey).toString(CryptoJS.enc.Utf8);
  return decrypted;
}


const inLocalStorageJwtManager = () => {
    // const navigate = useNavigate();

    const setAuthToken = (authToken, expiry) => {
        localStorage.setItem('bms_authToken', authToken);
        localStorage.setItem('bms_authTokenExpiry', expiry);

        // refreshToken(expiry);
        return true;
    };
    const setRefreshToken = (refreshToken, expiry) => {
        localStorage.setItem('bms_refreshToken', refreshToken);
        localStorage.setItem('bms_refreshTokenExpiry', expiry);

        // refreshToken(expiry);
        return true;
    };
    const getAuthToken = () => {
      const AuthTokenArray = {};
        if(localStorage.getItem('bms_authToken')){
          AuthTokenArray.authToken = localStorage.getItem('bms_authToken');
          AuthTokenArray.expiry = localStorage.getItem('bms_authTokenExpiry');
            // localStorage.setItem('bms_authTokenExpiry', expiry);
            return AuthTokenArray;
        }
        return false;
        
    };
    const getRefreshToken = () => {
        const RefreshTokenArray = {};
        if(localStorage.getItem('bms_refreshToken')){
          RefreshTokenArray.refreshToken = localStorage.getItem('bms_refreshToken');
          RefreshTokenArray.expiry = localStorage.getItem('bms_refreshTokenExpiry');
            // localStorage.setItem('bms_refreshTokenExpiry', expiry);
            return RefreshTokenArray;
        }
        return false;
    };

    const getNewRefreshToken = async () => {
        let isLoggedin = false;
        // const refreshToken = localStorage.getItem("bms_refreshToken");
        // const refreshToken = localStorage.getItem("bms_refreshToken");
        const oldRefreshToken = getRefreshToken();
        // console.log(refreshToken)
        if(!oldRefreshToken){
            return isLoggedin;
        }
        if (new Date(oldRefreshToken.expiry).getTime()/1000 < new Date().getTime()/1000) {
          // console.log("Loggedin");
          isLoggedin = false;
          return isLoggedin;
        }
        const postData = {
            refreshToken: oldRefreshToken.refreshToken,
          };
        const options = {
            headers: {
              'Content-Type': 'application/json',
            }
        };
      await  axios.post(`${process.env.REACT_APP_API_BASE_URL}auth/refresh-tokens`, postData, options)
            .then((res) => {
              const respData = res.data;
              console.log(res.data.tokens);
              if (respData.tokens) {
                const tokens = respData.tokens;
                // alert(respData.tokens.access);
                // localStorage.setItem('bms_user', JSON.stringify(res.data));
                // inMemoryJwt.setToken(respData.tokens.access);
                const authToken = tokens.access.token;
                const authTokenExpiry = tokens.access.expires;
                setAuthToken(authToken,authTokenExpiry);
                const refreshToken = tokens.refresh.token;
                const refreshTokenExpiry = tokens.refresh.expires;
                setRefreshToken(refreshToken,refreshTokenExpiry);
                setLoggedinUserData(respData.user);
                //console.log(res.data);
                isLoggedin = true;
                return isLoggedin;
              }
            })
            .catch((error) => {
              // what now?
              // console.log(error.response.data.message);
            //   setSubmitting(false);
              // Error
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                // in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error);
              }
              return isLoggedin;
            });
        return isLoggedin;
    }
    const checkAuth = async () => {
        let loggedIn = false;
        const bms_authToken = localStorage.getItem('bms_authToken');
        const bms_authTokenExpiry = localStorage.getItem('bms_authTokenExpiry');
        if (bms_authToken && bms_authTokenExpiry){
          if (new Date(bms_authTokenExpiry).getTime()/1000 > new Date().getTime()/1000) {
            // console.log("Loggedin");
            loggedIn = true;
          }else{
            await getNewRefreshToken().then((isAuth) => {
              // console.log("check auth", isAuth);
                  if(isAuth){
                      // console.log("loggedin",isAuth);
                      loggedIn=true;
                      return loggedIn;
                  }else{
                      // console.log("logout",isAuth);
                      return loggedIn;
                  }
            });
          }
          // console.log(bms_authTokenExpiry);
        }else{
          await getNewRefreshToken().then((isAuth) => {
            // console.log("check auth", isAuth);
                if(isAuth){
                    // console.log("loggedin",isAuth);
                    loggedIn=true;
                    return loggedIn;
                }else{
                    // console.log("logout",isAuth);
                    return loggedIn;
                }
          });
        }
        // console.log("checkauth func",getNewRefreshToken());
        return loggedIn; //console.log("phle");
    };
    const logout =  () => {
        // console.log("clicked");
        // navigate('/login', { replace: true });
        const options = {
          headers: {
            'Content-Type': 'application/json',
          }
        };
        const postData = {
          "refreshToken": localStorage.getItem("bms_refreshToken")
        };
        axios.post(`${process.env.REACT_APP_API_BASE_URL}auth/logout`, postData, options)
            .then((res) => {
              console.log('res', res);
            })
            .catch((err) => {
              console.log('error', err);
            });
          localStorage.removeItem("bms_refreshToken");
          localStorage.removeItem("bms_authToken");
          localStorage.removeItem("bms_authTokenExpiry");
          localStorage.removeItem("bms_refreshTokenExpiry");
          localStorage.removeItem("bms_user");
          localStorage.removeItem("dsr_permissions");
          window.location.href = process.env.REACT_APP_BASE_URL+"/login";
          return true;
    };
    const getLoggedinUserData = () => {
      // console.log("get user data");
      let userData = localStorage.getItem('bms_user');
      // console.log(JSON.parse(userData));
      if(userData){
        return JSON.parse(userData);
      }
      return {};
    };
    const updateLoggedinUserData = (key, value) => {
      let user = JSON.parse(localStorage.getItem('bms_user'));
      if(user){
        user[key] = value;
      }
      localStorage.setItem('bms_user', JSON.stringify(user));
      return true;
    }
    const setUserPermissions = (permissioonsObj) => {
      let UserPermissionDsr = permissioonsObj.find(o => o.resource === 'dsr');
      console.log(UserPermissionDsr);
      localStorage.setItem('dsr_permissions', JSON.stringify(UserPermissionDsr?UserPermissionDsr.permissions:{}));
      // localStorage.setItem('user_permissions', JSON.stringify(permissioonsObj));
      // const userPermissions = localStorage.getItem('user_permissions');
      // console.log(JSON.parse(userPermissions));
    }

    const getUserPermissions = async (resource) => {
      const userPermissions = localStorage.getItem(resource+'_permissions');
    }

    const setLoggedinUserData = (userData) => {
      const user = {
        name: userData.name? userData.name: (userData.fname + ' ' + (userData.lname?userData.lname:'')),
        email: userData.email,
        phone: userData.phone,
        id: userData.id,
        referral_code: userData.referral_code,
        profile_pic: userData.profile_pic,
        address: userData.address && userData.address,
        company: userData.company && userData.company,
        role: userData.role,
        is_sponsored: userData.is_sponsored,
        is_verified: userData.is_verified,
        workapplication: userData.workapplication
      };
      localStorage.setItem('bms_user', JSON.stringify(user));
      return true;
    }
    return {
        setAuthToken,
        getAuthToken,
        setRefreshToken,
        getRefreshToken,
        getNewRefreshToken,
        checkAuth,
        logout,
        setLoggedinUserData,
        getLoggedinUserData,
        setUserPermissions,
        getUserPermissions,
        encodeWithAES,
        decodeWithAES,
        updateLoggedinUserData
    }
}

export default inLocalStorageJwtManager();
