/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Container,
  makeStyles,
  Link,
  Button
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Page from 'src/components/Page';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
/*
 * Code for Data table theme change dark & light
 **/
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from './ThemeDatatable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  }
}));
function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const CustomerListView = (params) => {
  const { userId } = params;
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  function handleDelete(dsrId) {
    // console.log('id', dsrId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  // const [dsrList] = useState(data);
  // const columns = [
  //   {
  //     name: 'First Name',
  //     selector: 'first_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Last Name',
  //     selector: 'last_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Email',
  //     selector: 'email',
  //     sortable: true,
  //   },
  // ];
  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>Start Date</b>,
      selector: 'startDate',
      sortable: true,
      center: true,
      cell: (row) => moment(row.startDate).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>End Date</b>,
      selector: 'endDate',
      sortable: true,
      center: true,
      cell: (row) => moment(row.endDate).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Name</b>,
      selector: (row) => row.course? row.course.name : null,
      sortable: true,
      center: true,
      cell: (row) => row.course? row.course.name : 'NOT-AVAILABLE'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Days</b>,
      // selector: 'course.days',
      sortable: true,
      center: true,
      cell: (row) => row.duration? row.duration.days : '-'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Price</b>,
      // selector: 'course.days',
      sortable: true,
      center: true,
      cell: (row) => row.duration? row.duration.newPrice + '₹' : '-'
    }
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const fetchDsrs = async (page) => {
    if (!userId) {
        return false;
    }
    const offset = (page - 1) * perPage;
    setLoading(true);
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/courses`, options)
      .then(async (res) => {
        if(res.data.user.courses){
          setData(res.data.user.courses);
          console.log(res.data.user.courses);
          setTotalRows(res.data.user.courses.length);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchDsrs(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    // setData(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchDsrs(1);
  }, [perPage]);
  useEffect(() => {
    fetchDsrs(1);
    // ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'User Reports', 'Check in to User Daily report List page.');
  }, []);
  const ExpandedRow = (row) => {
    // console.log(row);
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>description</Th>
                      {/* <Th className={classes.respHeader}>Third parties details</Th>
                      <Th className={classes.respHeader}>Retailing</Th>
                      <Th className={classes.respHeader}>Recruitment</Th>
                      <Th className={classes.respHeader}>Notes</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{applicationOb.course?applicationOb.course.description:null}</Td>
                      {/* <Td className={classes.respHeader}>{applicationOb.tp_details}</Td>
                      <Td className={classes.respHeader}>{applicationOb.retailing}</Td>
                      <Td className={classes.respHeader}>{applicationOb.recruitment}</Td>
                      <Td className={classes.respHeader}>{applicationOb.notes}</Td> */}
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
      <Container maxWidth={false}>
        <Box mt={3}>
          {/* <DsrList dsrList={dsrList} /> */}
          <DataTableExtensions {...tableData}>
            <DataTable
              title="Course List"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              filter
              paginationServer
              paginationTotalRows={totalRows}
              // selectableRows
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              customStyles={{}}
              theme={rdtTheme} // Dynamically apply theme
              expandableRows
              expandableRowDisabled={row => row.disabled}
              expandableRowsComponent={<ExpandedRow />}
            />
          </DataTableExtensions>
        </Box>
      </Container>
  );
};

export default CustomerListView;
