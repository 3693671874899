/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Container,
  makeStyles,
  Link,
  Button
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Page from 'src/components/Page';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Toolbar from './Toolbar';
/*
 * Code for Data table theme change dark & light
 **/
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from 'src/components/DataTable/ThemeDatatable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: theme.spacing(3)
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  },
  dataBlock: {
    marginTop: '10px',
    paddingTop: '10px'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  }
}));
function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const CustomerListView = () => {
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  function handleDelete(dsrId) {
    // console.log('id', dsrId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await ApiCall.deleteDsrData(dsrId); 
            fetchDsrs(curPage);
            console.log('yes');
          }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  // const [dsrList] = useState(data);
  // const columns = [
  //   {
  //     name: 'First Name',
  //     selector: 'first_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Last Name',
  //     selector: 'last_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Email',
  //     selector: 'email',
  //     sortable: true,
  //   },
  // ];
  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>Date</b>,
      selector: 'date',
      sortable: true,
      center: true,
      cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>No. of PPT</b>,
      selector: 'no_of_ppt',
      sortable: true,
      center: true,
    },
    // {
    //   name: 'PPT details',
    //   selector: 'ppt_details',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'No of Third Parties',
    //   selector: 'no_of_tp',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Third parties details',
    //   selector: 'tp_details',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Retailing',
    //   selector: 'retailing',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Recruitment',
    //   selector: 'recruitment',
    //   sortable: true,
    //   center: true,
    // },
    {
      name: <b style={{ fontSize: '15px' }}>Action</b>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        <>
          <Link color="primary" size="small" component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>
            <IconButton color="primary" size="small" variant="contained">
              <EditIcon />
            </IconButton>
          </Link>
          <div>
            <IconButton style={{ color: '#ff6666' }} aria-label="delete" color="secondary" onClick={() => { handleDelete(row.id); }}>
              <DeleteIcon style={{ color: '#ff6666' }} />
            </IconButton>
          </div>
        </>
      )
    }
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [curPage, setCurPage] = useState(1);
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const fetchDsrs = async (page) => {
    const offset = (page - 1) * perPage;
    setCurPage(page);
    setLoading(true);
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const postData = {
      createdBy: getLoggedinUserData.id,
      skip: offset,
      limit: perPage
    };
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/dsreports`, postData, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.data.dsreports.length);
        setData(res.data.data.dsreports);
        setTotalRows(res.data.data.totalReports);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchDsrs(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    
    // setData(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchDsrs(1);
  }, [perPage]);
  useEffect(() => {
    // fetchDsrs(1);
    ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'daily Report', 'Check in to Daily report List page.');
  }, []);
  const ExpandedRow = (row) => {
    // console.log(row);
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>PPT Details</Th>
                      <Th className={classes.respHeader}>No of Third Parties</Th>
                      <Th className={classes.respHeader}>Third parties details</Th>
                      <Th className={classes.respHeader}>Retailing</Th>
                      <Th className={classes.respHeader}>Recruitment</Th>
                      <Th className={classes.respHeader}>Notes</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{applicationOb.ppt_details}</Td>
                      <Td className={classes.respHeader}>{applicationOb.no_of_tp}</Td>
                      <Td className={classes.respHeader}>{applicationOb.tp_details}</Td>
                      <Td className={classes.respHeader}>{applicationOb.retailing}</Td>
                      <Td className={classes.respHeader}>{applicationOb.recruitment}</Td>
                      <Td className={classes.respHeader}>{applicationOb.notes}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
    <Page
      className={classes.root}
      title="Daily Reports"
    >
      <Container className={classes.mainContainer} maxWidth={false}>
        {/* <Box mt={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={handleClick}>
              Material-UI
            </Link>
            <Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>
              Core
            </Link>
            <Link
              color="textPrimary"
              href="/components/breadcrumbs/"
              onClick={handleClick}
              aria-current="page"
            >
              Breadcrumb
            </Link>
          </Breadcrumbs>
        </Box> */}
        <Box mt={3}>
          <Toolbar className={classes.Toolbar} />
        </Box>
        {/* <Box mt={3}>
          <Card>
            <CardContent>
              <Box maxWidth={500}>
                FIlter Here
              </Box>
            </CardContent>
          </Card>
        </Box> */}
        <Card mt={3} className={classes.dataBlock}>
          {/* <DsrList dsrList={dsrList} /> */}
          <DataTableExtensions {...tableData}>
            <DataTable
              title="Daily Status Reports"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              filter
              paginationServer
              paginationTotalRows={totalRows}
              customStyles={{}}
              theme={rdtTheme} // Dynamically apply theme
              // selectableRows
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              expandableRows
              expandableRowDisabled={row => row.disabled}
              expandableRowsComponent={<ExpandedRow />}
            />
          </DataTableExtensions>
        </Card>
      </Container>
    </Page>
  );
};

export default CustomerListView;
