/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Link,
  Grid,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Visibility';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
// import moment from 'moment';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import { useSocket } from 'src/contexts/SocketContext';
/*
 * Code for Data table theme change dark & light
 **/
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from './ThemeDatatable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  }
}));

const List = (rest) => {
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  const {trainingId} = rest;
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      // Listen for user status changes from the server
      socket.on('viewerStatusChanged', ({ viewerId, status }) => {
        const userIndex = data.findIndex((viewer) => viewer.id === viewerId);
        if (userIndex !== -1) {
          // Create a copy of the users array
          const updatedUsers = [...data];
          // Update the status of the user at the found index
          updatedUsers[userIndex].status = status;
          // Set the updated array back in the state
          setData(updatedUsers);
          // console.log(updatedUsers);
        }
      });
    }
  }, [data]);
  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>Viewer</b>,
      selector: 'viewer.fname',
      sortable: true,
      cell: (row) => <span>{row.viewer? row.viewer.fname+ ' '+ (row.viewer.lname?row.viewer.lname:'') : ''}</span>
    //   right: true,
    //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Status</b>,
      selector: 'viewer.status',
      sortable: true,
      cell: (row) => <span style={{color: row.status == 'Online' ? 'green': 'red'}}>{row.status? row.status: ''}</span>
      //   right: true,
      //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Phone</b>,
      selector: 'viewer.phone',
      sortable: true,
      cell: (row) => <span>{row.viewer? row.viewer.phone: ''}</span>
      //   right: true,
      //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Action</b>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        row.viewer?
        (<>
          <Link
            color="primary"
            size="small"
            component={RouterLink}
            to={`/app/training/viewer/${trainingId}/${row.viewer.id}?name=${row.viewer.fname}${' '}${row.viewer.lname?row.viewer.lname:''}`}
          >
            <IconButton color="primary" size="small" variant="contained">
              <ViewIcon />
            </IconButton>
          </Link>
        </>) : ''
      )
    }
  ];
  const fetchViewers = async (page) => {
    if (!trainingId) {
        return false;
    }
    const offset = (page - 1) * perPage;
    setLoading(true);
    const postData = {
      ctype: 'Training',
      ctyperef: trainingId,
      skip: offset,
      limit: perPage
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?skip=${offset}&limit=${perPage}&ctype=Training&ctyperef=${trainingId}`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/viewers/get-all/${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data);
        setData(res.data.data.viewers);
        setTotalRows(res.data.data.totalViewers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchViewers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    // await fetchViewers(page);
    // setData(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchViewers(1);
  }, [perPage]);

  const ExpandedRow = (row) => {
    // console.log(row);
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Activity</Th>
                      <Th className={classes.respHeader}>Time</Th>
                      {/* <Th className={classes.respHeader}>Account Status</Th> */}
                      {/* <Th className={classes.respHeader}>Recruitment</Th>
                      <Th className={classes.respHeader}>Notes</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{applicationOb.object}</Td>
                      <Td className={classes.respHeader}>{ format(new Date(applicationOb.log_at), 'MMM d, hh:mm a')}</Td>
                      {/* <Td className={classes.respHeader}>{ applicationOb.is_verified ? 'Verified'  : 'Not Verified' }</Td> */}
                      {/* <Td className={classes.respHeader}>{applicationOb.recruitment}</Td>
                      <Td className={classes.respHeader}>{applicationOb.notes}</Td> */}
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
      <Container maxWidth={false}>
        <Box mt={3}>
          {/* <DsrList dsrList={dsrList} /> */}
          <DataTableExtensions {...tableData}>
            <DataTable
              // title="Viewers"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              filter
              paginationServer
              paginationTotalRows={totalRows}
              customStyles={{}}
              theme={rdtTheme} // Dynamically apply theme
              // selectableRows
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              // expandableRows
              // expandableRowDisabled={row => row.disabled}
              // expandableRowsComponent={<ExpandedRow />}
            />
          </DataTableExtensions>
        </Box>
      </Container>
  );
};

export default List;
