import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
// import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  deleteIcon: {
    marginTop: '15px'
  }
}));
export default function FormDialog() {
  const classes = useStyles();
  // console.log('calling slot');
  const [open, setOpen] = React.useState(false);
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const [isSubmitting, setSubmitting] = React.useState(false);
  const applicationSetting = getLoggedinUserData.workapplication[0];
  // console.log(applicationSetting.meetingSlots[0]);
  const [slots, setSlots] = React.useState(applicationSetting
    && applicationSetting.meetingSlots && applicationSetting.meetingSlots.length > 0
    ? applicationSetting.meetingSlots : [{
      id: 1, time: '', day: '', date: ''
    }]);

  const handleSubmit = () => {
    setSubmitting(true);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    const postData = {
      meetingSlots: slots
    };
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/save-form-setting/`, postData, options)
      .then((res) => {
        setSubmitting(false);
        const respData = res.data;
        inLocalStorageJwt.setLoggedinUserData(respData.user);
        setOpen(false);
        // console.log(respData);
        // console.log(res.data.data.tokens);
        // alert(respData.tokens.access);
        // localStorage.setItem('bms_user', JSON.stringify(res.data));
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (res.data.status === true) {
          //  navigate('/app/dashboard', { replace: true });
          setSubmitting(false);
          setOpen(false);
          // setSlots([{
          //   id: 1, time: '', day: '', date: ''
          // }]);
        }
      })
      .catch((error) => {
        // what now?
        // console.log(error.response.data.message);
        setSubmitting(false);
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest
          // in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error);
        }
      });
  };
  const handleChange = (slot) => {
    slots.filter((item) => {
      if (item.id === slot.id) {
        item.time = slot.time;
        item.day = slot.day;
        item.date = slot.date;
        // console.log('hanji', item);
      }
      return item;
    });
    setSlots([...slots]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleReset = () => {
    setSlots([{
      id: 1, time: '', day: '', date: ''
    }]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addValue = () => {
    setSlots([...slots, {
      id: (slots.length + 1), time: '', day: '', date: ''
    }]);
  };
  const deleteValue = (jump) => {
    setSlots(slots.filter((j) => j.id !== jump));
  };
  React.useEffect(() => {
    console.log('Dialog open state changed:', open);
  }, [open]);
  return (
    <div>
      <Button variant="outlined" style={{ fontSize: isMobile ? '12px' : '14px' }} size="small" color="primary" onClick={handleClickOpen}>
        Form Setting
      </Button>
      <Dialog open={open} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Form setting</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add your available slot.
          </DialogContentText>
          {/* <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="time"
                InputLabelProps={{
                  shrink: true,
                }}
                type="time"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Day</InputLabel>
                <Select
                  id="demo-simple-select"
                  labelId="demo-simple-select-label"
                  margin="dense"
                  fullWidth
                  onChange={handleChange}
                  label="select date"
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container spacing={1}>
            {slots.map((slot) => (
              <>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id={`name${slot.id}`}
                    label="time"
                    defaultValue={slot.time}
                    onChange={(event) => handleChange({
                      id: slot.id, time: event.target.value, day: slot.day, date: slot.date
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id={`{demo-simple-select-label}${slot.id}`}>Day</InputLabel>
                    <Select
                      id="demo-simple-select"
                      labelId={`{demo-simple-select-label}${slot.id}`}
                      margin="dense"
                      fullWidth
                      defaultValue={slot.day}
                      onChange={(event) => handleChange({
                        id: slot.id, time: slot.time, day: event.target.value, date: slot.date
                      })}
                      label="select date"
                    >
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {
                      slot.day === 'other'
                      && (
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Date"
                          defaultValue={slot.date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => handleChange({
                            id: slot.id, time: slot.time, day: slot.day, date: event.target.value
                          })}
                          type="date"
                          inputProps={{ min: Date() }}
                          fullWidth
                        />
                      )
                    }
                  </FormControl>
                </Grid>
                {
                  slot.id > 1
                  && (
                  <Grid item xs={2}>
                    <div
                      className={classes.deleteIcon}
                      onClick={() => deleteValue(slot.id)}
                    >
                      <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Grid>
                  )
                }
              </>
            ))}
          </Grid>
        </DialogContent>
        <Button onClick={addValue} color="primary">
          Add More Slot
        </Button>
        <DialogActions>
          <Button onClick={handleReset} color="primary">
            Reset
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isSubmitting}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
