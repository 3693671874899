import React from 'react'; // ✅ Import React to fix JSX error
import {
  Grid, makeStyles, Card, CardContent
} from '@material-ui/core';
import Breadcrumbs from 'src/components/BreadCrumbs/BreadCrumbs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
}));

function MyComponent() {
  const classes = useStyles();
  const itemsArray = [{ page: 'Dashboard', href: '/app/dashboard' }];

  return (
    <Grid container spacing={3}>
      {/* Breadcrumbs Card */}
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Breadcrumbs itemsArray={itemsArray} lastPage={{ page: 'Lead List' }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default MyComponent;
