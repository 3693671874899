/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Grid, Button } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';
import Countdown from 'react-countdown';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import {
  Eye as ViewIcon,
  Copy as CopyIcon,
  Trash as DeleteIcon
} from 'react-feather';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { toast } from 'react-toastify';
import PremiereCreate from './PremiereCreate';
import { green, purple, red } from '@material-ui/core/colors';
import TrainingContext from 'src/contexts/TrainingContext';


const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function MediaControlCard(params) {
  const classes = useStyles();
  const premiere  = params.premiereData;
  const training = params.trainingData;
  // const { premiereData, trainingData } = params;
  const [copySuccess, setCopySuccess] = useState('');
  const [premDelete, setPremDelete] = useState(false);
  // const [premiere, setPremiere] = useState(premiereTemp);

  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const trainingContext = useContext(TrainingContext);

  const theme = createTheme({
    palette: {
      primary: red,
    },
  });
  const Completionist = () => <span>Your Premiere is Expired!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span> 
          <b>{days}</b> Days:<b>{hours}</b>  Hrs: <b>{minutes}</b> Min:<b>{seconds}</b> Sec
        </span>
      );
    }
  };
  function handleCopy(trainingId) {
    // console.log(trainingId, permissions);
    //const encodedData = btoa('?premiere=true&prem_time='+premiere.prem_time); // encode a string
    const originalData = `?premiere=true&prem_time=${premiere.prem_time}`;
    const secretKey = process.env.REACT_APP_ENC_SECRET_KEY;
    const encodedData = inLocalStorageJwt.encodeWithAES(originalData, secretKey);
    // const decodedData = atob(encodedData); // decode the string
    // console.log(encodedData, decodedData);
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/training/details-page/${trainingId}?type=${encodedData}`);
    setCopySuccess(' Copied!');
  }

  async function handleCopyInvitation(trainingId) {
    // console.log(trainingId, permissions);
    const originalData = `?premiere=true&prem_time=${premiere.prem_time}`;
    const secretKey = process.env.REACT_APP_ENC_SECRET_KEY;
    const encodedData = inLocalStorageJwt.encodeWithAES(originalData, secretKey);
    // const decodedData = inLocalStorageJwt.decodeWithAES(encodedData, secretKey);
    // console.log(decodedData);
    let textToCopy = `Dear Candidate, Here is the training details!\n\nTraining Name: ${training.data ? training.data.name : training.name}\n\n`;
    textToCopy += `Trainer: ${training.data ? training.data.trainer : training.trainer}\n\n`;
    textToCopy += `Topic: ${training.data ? training.data.topic : training.topic}\n\n`;
    textToCopy += `Note: ${training.data ? training.data.notes : training.notes}\n\n`;
    textToCopy += `Time: ${premiere.prem_time ? moment(premiere.prem_time).format('MMM DD, YYYY, hh:mm A') : ''}\n\n`;
    textToCopy += `Join Meeting: \n ${process.env.REACT_APP_BASE_URL}/training/details-page/${trainingId}?type=${encodedData}\n`;
    navigator.clipboard.writeText(textToCopy);
    setCopySuccess(' Copied!');
  }
  // const theme = useTheme();
   /* Delete training function. */
   const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
   function deletePremiere(premiereId) {
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/premieres/delete/${premiereId}`, options)
      .then((resp) => {
        // console.log(resp);
        // trainingContext.getPremiere(premiere.training);
        toast.success(`${resp.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPremDelete(true);
        // premiere = {};
        // navigate('/app/trainings');
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }
  function handleDeletePrem(trainingId) {
    // console.log('id', trainingId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); deletePremiere(trainingId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  useEffect(()=> {
    // console.log("hello", premiere);
    const isEmpty = Object.keys(premiere).length === 0  
    if(!isEmpty){
      setPremDelete(false);
    }
  }, [premiere]);
  
  return (
    <>
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Grid
              md={12}
              xs={12}
              container
              justifyContent="flex-end"
            >
                <PremiereCreate trainingData={training} />
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography component="h5" variant="h5">
                  Premiere details
                </Typography>
              </Grid>
              {
              premiere && premiere.training && !premDelete &&
              (
                <>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    {moment(premiere.prem_time).format('MMM DD, YYYY, hh:mm A')}
                  </Grid>
                  <Grid
                    className={classes.statsItem}
                    item
                  >
                    <Typography
                      color="textSecondary"
                      display="inline"
                      variant="body2"
                    >
                      {/* <Button onClick={() => console.log(task.id)}>
                        <ViewIcon
                          className={classes.statsIcon}
                          color="action"
                        />
                        View
                      </Button> */} 
                      <b>Time Remaining: </b>
                      <Countdown
                        // onTick={(e) => { console.log(e); }}
                        date={new Date(premiere.prem_time)} renderer={renderer}/>
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.statsItem}
                    item
                  >
                    <Typography
                      display="inline"
                    >
                      <Button
                        onClick={() => handleCopy(premiere.training)}
                        variant="contained"
                        color="secondary"
                      >
                        <CopyIcon
                          className={classes.statsIcon}
                        />
                        Copy Premiere Link
                      </Button>
                      {copySuccess}
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.statsItem}
                    item
                  >
                    <Typography
                      display="inline"
                    >
                      <Button
                        onClick={() => handleCopyInvitation(premiere.training)}
                        variant="contained"
                        color="secondary"
                      >
                        <CopyIcon
                          className={classes.statsIcon}
                        />
                        Copy Premiere Invitation
                      </Button>
                      {copySuccess}
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.statsItem}
                    item
                  >
                    <Typography
                      // color="textSecondary"
                      display="inline"
                      // variant="body2"
                    >
                      <ThemeProvider theme={theme}>
                        <Button
                            onClick={() => handleDeletePrem(premiere.id)}
                            variant="contained"
                            color="primary"
                          >
                            <DeleteIcon
                              className={classes.statsIcon}
                              // style={{ color: '#ff6666' }}
                            />
                            Delete Premiere
                          </Button>
                      </ThemeProvider>
                    </Typography>
                  </Grid>
                </>
              )}
              { ((Object.keys(premiere).length == 0) || premDelete) &&
              (<Grid
                item
                md={12}
                xs={12}
              >
                No Premiere available
              </Grid>)}
            </Grid>
          </CardContent>
        </div>
        {/* <CardMedia
          className={classes.cover}
          // image="/static/images/cards/live-from-space.jpg"
          title="Live from space album cover"
        /> */}
      </Card>
    </>
  );
}
