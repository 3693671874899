/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Icon,
  SvgIcon,
  Button,
  Zoom,
  useTheme,
  IconButton, 
  Fab
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CategoryContext from 'src/contexts/CategoryContext';
import TrainingContext from 'src/contexts/TrainingContext';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { isMobile } from 'react-device-detect';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ShareFolder from './ShareFolder';
import Update from './Update';
import Menu from './Menu';

const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  folderIcon: {
    color: theme.palette.mode === 'dark' ? '#64b5f6' : '#3f51b5',
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  Btnmargin: {
    margin: theme.spacing(1),
  },
  fabDelete: {
    float: 'right',
    color: 'red',
    margin: theme.spacing(1)
  },
  fabMenu: {
    float: 'right',
    color: 'blue',
  },
}));

const file = 'https://bmsglobal.s3.ap-south-1.amazonaws.com/uploads/resume/sell+like+crazy+preface.pdf';
// const type = 'pdf';

const CategoryCard = ({ className, category, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const categoryContext = useContext(CategoryContext);
  const trainingContext = useContext(TrainingContext); 
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const options = {
    headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${AuthToken}`,
    }
  };
  const deleteCategory = (catId) => {
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/categories/delete/${catId}`, options)
      .then((resp) => {
        console.log(typeof categoryContext.getCategories);
        if (typeof categoryContext.getCategories === "function") { 
          // safe to use the function
          categoryContext.getCategories();
        }

        if (typeof trainingContext.getCategories === "function") { 
          // safe to use the function
          trainingContext.getCategories();
        }
        // categoryContext.getCategories();
        // trainingContext.getCategories();
        toast.success(`${resp.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/app/tcategories');
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }
  function handleDelete(catId) {
    // console.log('id', trainingId);
    confirmAlert({
      title: 'All trainings of this folder will be moved to default folder.',
      message: 'Are you sure to delete this folder?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            console.log('yes'); 
            deleteCategory(catId); 
          }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        console.log("click");
        isMobile && navigate(`/app/tcategories/${category.id}/trainings`);
        break;
      case 2:
        console.log("double click");
        navigate(`/app/tcategories/${category.id}/trainings`);
        break;
      case 3:
        console.log("triple click");
        break;
    }
  };
  return (
    <Card
      className={clsx(classes.root, className)}
      //style={{height:"105vw", display: 'block'}}
      {...rest}
    >
      <CardContent>
      {
          category.name !== 'default' &&
        <div 
          aria-label="menu" 
          className={classes.fabMenu}
        >
          <Menu {...category} />
        </div>
      }
        <Box
          // height="600px"
          display="block"
          justifycontent="center"
          alignItems="center"
          // mb={3}
        >
          <Typography
            align="center"
            color="textPrimary"
            onClick={handleClick}
            // gutterBottom
          >
            {
            category.name !== 'default' ?
                <FolderIcon
                  style={{ fontSize: 200 }}
                  justifycontent="center"
                  className={classes.folderIcon}
                />
                :
                <FolderSpecialIcon
                  style={{ fontSize: 200 }}
                  justifycontent="center"
                  className={classes.folderIcon}
                />
            }
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            // gutterBottom
          >
            {category.name}
          </Typography>
        </Box>
        {
          category.name !== 'default' &&
          <>
            {/* <IconButton className={classes.Btnmargin}> */}
              <Fab
                aria-label="delete"
                size='small'
                className={classes.fabDelete}
                onClick={() => handleDelete(category.id)}
              >
                <DeleteIcon />
              </Fab>
            {/* </IconButton> */}
            {/* <Fab 
              size='small'
              aria-label="delete" 
              className={classes.fabEdit}
              onClick={() => handleDelete(category.id)}
            >
              <EditIcon />
            </Fab> */}
            <Update {...category} />
            <ShareFolder category = {category} />
          </>
        }
      </CardContent>
    </Card>
  );
};

CategoryCard.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object.isRequired
};

export default CategoryCard;
