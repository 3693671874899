import React from 'react'; // ✅ Import React to fix JSX error
import PropTypes from 'prop-types'; // ✅ Import PropTypes for validation
import {
  Grid, makeStyles, Card, CardContent
} from '@material-ui/core';
import Breadcrumbs from 'src/components/BreadCrumbs/BreadCrumbs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
}));

function MyComponent({ leadList }) {
  const classes = useStyles();
  const itemsArray = [
    { page: 'Dashboard', href: '/app/dashboard' },
    { page: 'LMS', href: '/app/lms' }
  ];

  return (
    <Grid container spacing={3}>
      {/* Breadcrumbs Card */}
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Breadcrumbs itemsArray={itemsArray} lastPage={{ page: leadList ? leadList.name : 'Details' }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
// ✅ Add PropTypes to validate `leadList`
MyComponent.propTypes = {
  leadList: PropTypes.shape({
    name: PropTypes.string.isRequired, // Ensure `leadList.name` is a string
  }),
};
export default MyComponent;
