/* eslint-disable */
import React from 'react';
import {
  Grid,
  Typography,
  Breadcrumbs,
  makeStyles,
  } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Link as RouterLink } from 'react-router-dom';

// function handleClick(event) {
//   event.preventDefault();
//   console.info('You clicked a breadcrumb.');
// }
const useStyles = makeStyles((theme) => ({
  root: {
  },
  breadCrumbLink: {
    color: theme.palette.mode === 'dark' ? '#FFD700' : '#4051B5',
  },
}));

export default function SimpleBreadcrumbs({ itemsArray, lastPage }) {
  const classes = useStyles();
  
  return (
    <Grid
      container
      spacing={3}
    >
      <>
        <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
        >
            <Breadcrumbs aria-label="breadcrumb">
                {
                    itemsArray.map((item) => (
                        <RouterLink to={item.href} key={uuid()} className={classes.breadCrumbLink}>
                        {item.page}
                        </RouterLink>
                    ))
                }
            <Typography color="textPrimary">{lastPage.page}</Typography>
            </Breadcrumbs>
        </Grid>
      </>
    </Grid>
  );
}
