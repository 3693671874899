import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2)
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.85rem',
    marginTop: theme.spacing(0.5)
  }
}));

const AddLeadForm = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const validationSchema = Yup.object({
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
      .required('Phone is required'),
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .max(255, 'Max 255 characters')
      .trim(),
    email: Yup.string().email('Invalid email format').trim(),
    qualification: Yup.string()
      .min(2, 'Must be at least 2 characters')
      .max(100, 'Max 100 characters')
      .trim(),
    address: Yup.string().max(500, 'Maximum 500 characters').trim()
  });

  return (
    <>
      {/* Add Lead Button */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<PersonAddIcon />}
        size="small"
        onClick={() => setOpen(true)}
      >
        Add Lead
      </Button>

      {/* Modal for Lead Form */}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add New Lead</DialogTitle>
        <Formik
          initialValues={{
            phone: '', name: '', email: '', qualification: '', address: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            const response = await onSubmit(values);
            if (response.status === 200) {
              resetForm(); // Reset only on success
              setOpen(false);
            }
          }}
        >
          {({
            handleChange, handleBlur, values, errors, touched
          }) => (
            <Form>
              <DialogContent>
                <TextField
                  fullWidth
                  label="Phone *"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  className={classes.formField}
                />

                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  className={classes.formField}
                />

                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  className={classes.formField}
                />

                <TextField
                  fullWidth
                  label="Qualification"
                  name="qualification"
                  value={values.qualification}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.qualification && Boolean(errors.qualification)}
                  helperText={touched.qualification && errors.qualification}
                  className={classes.formField}
                />

                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                  className={classes.formField}
                />

              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

AddLeadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default AddLeadForm;
