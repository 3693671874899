/* eslint-disable */
import axios from 'axios';
import { toast } from "react-toastify";
import inLocalStorageJwt from "./inLocalStorageJwt";

const createLead = async (leadlistId, leadBody) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/leads/create/${leadlistId}`,
      leadBody,
      options
    );
    toast.success(`${response.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    return { status: 200, lead: response.data.lead, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    return { status: 400, lead: "", message: error.response.data.message };
  }
};

const createBulkLead = async (leadlistId, leadBodyObj) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/leads/bulk-create/${leadlistId}`,
      { leads: leadBodyObj },
      options
    );
    toast.success(`${response.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    return { status: 200, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    return { status: 400, message: error.response.data.message };
  }
};
const getLeadById = async (leadId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthToken}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/leads/view/${leadId}`,
      options
    );
    return { status: 200, lead: response.data.lead, message: response.data.message };
  } catch (error) {
    console.error(error);
    return { status: 400, lead: "", message: error.response.data.message };
  }
};

const deleteLead = async (leadId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthToken}`,
    },
  };

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/leads/delete/${leadId}`,
      options
    );
    return { status: 200, message: response.data.message };
  } catch (error) {
    console.error(error);
    return { status: 400, message: error.response.data.message };
  }
};

const getAllLeads = async (fields) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthToken}`,
    },
  };
  const { skip, limit, name, desc, leadlistId, searchQuery } = fields;
  let searchQuery2 = `?skip=${skip}&limit=${limit}&searchQuery=${searchQuery}`;

  if (name) {
    searchQuery2 += `&name=${name}`;
  }
  if (desc) {
    searchQuery2 += `&desc=${desc}`;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/leads/list/${leadlistId}/${searchQuery2}`,
      options
    );
    return {
      status: 200,
      leads: response.data.result.leads,
      totalRecords: response.data.result.totalRecords,
      message: response.data.message,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 400,
      leads: "",
      message: error.response.data.message,
    };
  }
};

export default {
  createLead,
  createBulkLead,
  getLeadById,
  deleteLead,
  getAllLeads,
};
