import React, { useState, useEffect } from 'react';
import {
  Card,
  Grid,
  makeStyles,
  Button,
  Container
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useParams } from 'react-router-dom';
import GroupMemberApiCall from 'src/utils/GroupMemberApiCall';
/**
 * Code for Data table theme change dark & light
 */
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from 'src/components/DataTable/ThemeDatatable';
import Toolbar from './Toolbar';
import OfficeDataTable from './OfficeDataTable';
import GroupCard from './GroupCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formExpndCard: {
    minWidth: 275,
    margin: theme.spacing(3)
  },
  respHeader: {
    margin: '10px',
    padding: '10px'
  },
  statusTaskLbl: {
    padding: '3px',
    borderRadius: '5px',
    color: 'white'
  },
  headerSt: {
    fontSize: '15px'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  },
  Toolbar: {
    margin: '10px'
  },
}));

const List = () => {
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  // const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [groupMembersData, setGroupMembersData] = useState([]);
  const [groupData, setGroupData] = useState({});

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { groupId } = useParams();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  // eslint-disable-next-line no-shadow
  const fetchGroupMembers = async (page, perPage) => {
    const offset = (page - 1) * perPage;
    setLoading(true);
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    const filters = `?groupid=${groupId}&skip=${offset}&limit=${perPage}`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/group-members${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        console.log(res.data.data);
        setGroupMembersData(res.data.data.groupMembers);
        setTotalRows(res.data.data.totalGroupMembers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  async function handleApprove(gmId) {
    try {
      const resp = await GroupMemberApiCall.changeGMStatus(gmId, 'accepted');
      // console.log('group member created', resp);
      if (resp.status === 200 && resp.member) {
        // console.log(resp.groupmember);
        fetchGroupMembers(currentPage, perPage);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      // setSubmitting(false);
    }
  }
  function handleReject(memberId) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes', memberId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }

  const columns = [
    {
      name: <span className={classes.headerSt}>Name</span>,
      selector: 'userId',
      sortable: true,
      // right: true,
      cell: (row) => <div style={{ width: '100%' }}>{row.userId.fname}</div>
    },
    {
      name: <span className={classes.headerSt}>Role</span>,
      selector: 'roles',
      sortable: true,
      center: true,
      cell: (row) => (
        <div>
          { row.roles[0] }
          {' '}
        </div>
      )
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (row) => (
        <div>
          { row.status }
          {' '}
        </div>
      )
    },
    {
      name: <span className={classes.headerSt}>Action</span>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        row.status === 'pending'
        && (
        <>
          <Button color="primary" size="small" style={{ fontSize: '10px', whiteSpace: 'nowrap' }} variant="contained" onClick={() => { handleApprove(row.id); }}>
            Approve
          </Button>
          <Button aria-label="delete" size="small" color="secondary" onClick={() => { handleReject(row.id); }}>
            Reject
          </Button>
        </>
        )
      )
    }
  ];
  const fetchGroup = async () => {
    setLoading(true);
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/groups/view/${groupId}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.group);
        setGroupData(res.data.group);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchGroupMembers(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchGroupMembers(page, perPage);
    // setData(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await fetchGroup(groupId);
      await fetchGroupMembers(currentPage, perPage);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return (
    <Page
      className={classes.root}
      title="Group & Members"
    >
      <Container className={classes.mainContainer} maxWidth={false}>
        <Toolbar groupId={groupId} className={classes.Toolbar} />
        {groupData && (
          <GroupCard
            groupName={groupData.gname}
            description={groupData.gdesc}
            groupId={groupId}
          />
        )}
        <OfficeDataTable groupId={groupId} />
        <Card className={classes.mainContainer}>
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12} lg={12} xl={12} md={12}>
              <DataTable
                title={`${groupData.gname} Partners`}
                columns={columns}
                data={groupMembersData}
                progressPending={loading}
                pagination
                filter
                customStyles={{}}
                theme={rdtTheme} // Dynamically apply theme
                paginationServer
                paginationTotalRows={totalRows}
                selectableRows
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default List;
