import React, { useState, useEffect } from 'react';
import {
  IconButton,
  makeStyles,
} from '@material-ui/core';
import DataTable from 'react-data-table-component';
import MeetingApiCall from 'src/utils/MeetingApiCall';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
/**
 * Code for Data table theme change dark & light
 */
import { useTheme } from '@material-ui/core/styles';
import getRdtTheme from 'src/components/DataTable/ThemeDatatable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  deleteIcon: {
    color: 'red',
    fontSize: '10px',
  },
  editIcon: {
    color: 'blue',
    fontSize: '10px',
  }
}));
const MeetingList = (params) => {
  const classes = useStyles();
  const muiTheme = useTheme(); // Get MUI theme
  const rdtTheme = getRdtTheme(muiTheme.palette.mode); // ✅ Get Ready-Made Theme
  const { officeId } = params;
  const [meetings, setMeetings] = useState([]);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const handleDelete = (meetingId) => {
    console.log(meetingId);
  };
  const fetchMeetings = async () => {
    try {
      const resp = await MeetingApiCall.meetingList(officeId, { skip, limit: perPage });
      if (resp.status === 200 && resp.meetings) {
        setMeetings(resp.meetings);
        setTotalRows(resp.totalMeetings);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
    // Handle error
    }
  };
  useEffect(() => {
    // Fetch meetings from the server based on page and perPage
    fetchMeetings();
  }, [page, perPage]);

  const columns = [
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Slot',
      selector: 'slot',
      sortable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
    },
    {
      name: 'Frequency',
      selector: 'frequency',
      sortable: true,
    },
    {
      name: 'Passcode',
      selector: 'passcode',
      sortable: true,
    },
    {
      name: <span>Action</span>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        <>
          <IconButton className={classes.deleteIcon} onClick={() => { handleDelete(row.id); }}>
            <DeleteIcon />
          </IconButton>
          <IconButton className={classes.editIcon} onClick={() => { handleDelete(row.id); }}>
            <EditIcon />
          </IconButton>
        </>
      )
    }
  ];

  const handlePageChange = (pg) => {
    setSkip((pg - 1) * perPage);
    setPage(pg);
  };

  const handlePerRowsChange = async (newPerPage, pg) => {
    setSkip((pg - 1) * newPerPage);
    setPerPage(newPerPage);
    setPage(pg);
  };

  return (
    <div>
      <DataTable
        title="Meeting List"
        columns={columns}
        data={meetings}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        customStyles={{}}
        theme={rdtTheme} // Dynamically apply theme
        paginationPerPage={perPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </div>
  );
};

export default MeetingList;
