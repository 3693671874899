import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Button, Box, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import LeadlistApiCall from 'src/utils/LeadlistApiCall';
import moment from 'moment';
// Styles
const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: 400,
    margin: 'auto',
    padding: theme.spacing(3),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

// Validation Schema
const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, 'Name must be at least 3 characters long')
    .max(255, 'Name cannot exceed 255 characters')
    .required('Name is required'),
  description: Yup.string()
    .min(3, 'Description must be at least 3 characters long')
    .max(500, 'Description cannot exceed 500 characters')
    .required('Description is required'),
});

// Component
const CreateLeadlistForm = ({ handleClose }) => {
  const classes = useStyles();

  const initialValues = {
    name: moment().format('DD MMM YYYY hh:mm:ss A'),
    description: '',
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // console.log(values);
    try {
      const resp = await LeadlistApiCall.createLeadlist(values);
      // console.log('leadlist created', resp);
      if (resp.status === 200 && resp.leadlist) {
        // onCouponCreated(resp.coupon);
        resetForm();
        handleClose();
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Box className={classes.formContainer}>
      <Typography variant="h5" gutterBottom>
        Create Leadlist
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Field
              as={TextField}
              name="name"
              label="Name"
              fullWidth
              variant="outlined"
              className={classes.formField}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <Field
              as={TextField}
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              className={classes.formField}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Create Leadlist'}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

// PropTypes for validation
CreateLeadlistForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};

export default CreateLeadlistForm;
