import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';

const LeadDetailsDrawer = ({ lead, open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if mobile

  return (
    <>
      {/* View Button */}
      {/* <IconButton onClick={() => setOpen(true)}>
        <VisibilityIcon />
      </IconButton> */}

      {/* Drawer Component */}
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        fullScreen={isMobile} // Fullscreen on mobile
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '400px', // Full width on mobile, 400px on desktop
          },
        }}
      >
        <div style={{ padding: '20px' }}>
          <IconButton onClick={onClose} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Lead Details</Typography>
          <Typography>
            Name:
            {lead?.name}
          </Typography>
          <Typography>
            Phone:
            {lead?.phone}
          </Typography>
          <Typography>
            Email:
            {lead?.email}
          </Typography>
          <Typography>
            Status:
            {lead?.status}
          </Typography>
        </div>
      </Drawer>
    </>
  );
};

LeadDetailsDrawer.propTypes = {
  lead: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LeadDetailsDrawer;
