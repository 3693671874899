import { createTheme } from 'react-data-table-component';

// 🔥 Create & Register Light Theme
createTheme('lightTheme', {
  text: {
    primary: '#000000',
    secondary: '#4B5563',
  },
  background: {
    default: '#FFFFFF',
  },
  divider: {
    default: 'rgba(0,0,0,0.1)',
  },
  highlightOnHover: {
    default: '#F3F4F6',
    text: '#000000',
  },
});

createTheme('darkTheme', {
  text: {
    primary: '#FFFFFF',
    secondary: '#BBBBBB',
  },
  background: {
    default: '#121212',
  },
  divider: {
    default: 'rgba(255,255,255,0.1)',
  },
  highlightOnHover: {
    default: '#333333',
    text: '#FFFFFF',
  },
  button: {
    default: '#FFFFFF', // Ensures pagination icons (prev/next) are white
    hover: 'rgba(255,255,255,0.8)',
    focus: 'rgba(255,255,255,0.6)',
    disabled: 'rgba(255,255,255,0.3)',
  },
});
const getRdtTheme = (mode) => (mode === 'dark' ? 'darkTheme' : 'lightTheme');

export default getRdtTheme;
